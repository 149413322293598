import { Directive, ViewContainerRef } from '@angular/core';
import { PageToComponentDirective as PTCD } from 'ets-fe-ng-sdk';

@Directive({
  selector: '[appPageToComponent]',
  standalone: true,
})
export class PageToComponentDirective extends PTCD {
  constructor(public viewContainerRef: ViewContainerRef) {
    super(viewContainerRef);
  }
}
