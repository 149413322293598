import { Component } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { NotificationsService } from './notifications.service';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, MatDialogModule]
})
export class NotificationsComponent {
  constructor(public nS: NotificationsService,public dialogRef:MatDialogRef<NotificationsComponent>,) {}
}
