import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialogRef as MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { BtnComponent } from 'ets-fe-ng-sdk';

/**
 * Modal header template
 */
@Component({
  selector: 'modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, AsyncPipe, TranslatePipe, BtnComponent],
})
export class ModalHeaderComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() header: string;
  /**
   * Value to pass into the close function of the modal
   */
  @Input() onCloseValue: any;
  constructor() {}

  ngOnInit(): void {
    // console.log(this.header);
  }
  close() {
    this.dialogRef.close(this.onCloseValue);
  }
}
