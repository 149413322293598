// export { TranslationService } from 'ets-fe-ng-sdk';

import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ELanguage, ITranslatorConfig, TranslationService as Ts } from 'ets-fe-ng-sdk';
import { ApiService } from './api.service';
import { AppLocalCacheService } from './cache/local-cache.service';
import { StorageService } from './storage.service';
import { from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/auth/auth.reducer';
import { LoaderService } from './page-loader.service';
import { LabelsService } from './customization/labels.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends Ts {
  constructor(
    public apiService: ApiService,
    public storageS: StorageService,
    public lCacheS: AppLocalCacheService,
    private pageLS: LoaderService,
    public store: Store,
    private labelS: LabelsService,
    tS: Ts,
  ) {
    super(apiService, storageS, lCacheS);
    // debugger;
    this.sourceLanguage = environment.sourceLanguage;
    this.store.select(AuthStore.selectors.needsTranslation).subscribe((r) => {
      // debugger;
      this.targetLanguage = r;
      if (r) this.startInterval();
      else this.stopInterval();
    });
    tS.pipeTransformer = this.pipeTransformer;
    this.toastNotificationService.labelPipe = (text) => from(this.translateHTMLPromise(text));
    this.toastNotificationService.labelPipe = null;
  }
  // startInterval() {
  //   if (this.intervalSub && !this.intervalSub.closed) return;
  //   this.intervalSub = interval(1000)
  //     .pipe(
  //       filter(()=>this.translationRequestMatrix()),
  //       tap(async () => {
  //         const body = clone(this.translationRequestMatrix());
  //         this.translationRequestMatrix.set({});
  //         if (Object.keys(body)?.length) {
  //           // debugger;
  //           const transGlossary = this.transGlossary || (await lastValueFrom(this.getGlossary()));
  //           lastValueFrom(
  //             this.apiService.postString(
  //               this.baseURL +
  //                 (this.transGlossary?.code
  //                   ? `language?glossaryCode=${this.transGlossary?.code}&sourceLang=${this.sourceLanguage}&targetLang=${this.targetLanguage}`
  //                   : `language/${this.targetLanguage}`),
  //               Object.keys(body)
  //                 .map((id) => this.tagger(+id, body[id]))
  //                 .join(this.separator),
  //             ),
  //           )
  //             .then((r) => {
  //               this.translationResponseMatrix.next(this.detagger(r));
  //             })
  //             .catch((e) => {
  //               console.error(e);
  //             });
  //         }
  //       }),
  //     )
  //     .subscribe();
  // }
  pipeTransformer = (text: string | number, config?: ITranslatorConfig) => {
    return new Observable<string>((res) => {
      // res.next(text as any);
      // debugger
      const replacedText = this.labelS.replaceLabel(text?.toString());
      if (replacedText) text = replacedText;
      if (config?.ignore || !(environment.translate && environment.targetLanguage)) {
        res.next(replacedText || text?.toString());
        res.complete();
        res.unsubscribe();
      } else {
        const useLoader = !(config?.noLoader == true);
        if (useLoader) this.pageLS.play();
        this.translateHTML(
          text as any,
          environment.sourceLanguage,
          environment.targetLanguage,
          config,
        ).subscribe({
          next: (r) => {
            // debugger;
            res.next(r);
            res.complete();
            res.unsubscribe();
            if (useLoader) this.pageLS.stop();
          },
          error: (e) => {
            // debugger;
            res.complete();
            res.unsubscribe();
            if (useLoader) this.pageLS.stop();
          },
        });
      }
    });
  };
  getGlossary(sourceLanguage?: ELanguage, targetLanguage?: ELanguage) {
    // debugger;
    return super.getGlossary(environment.sourceLanguage, environment.targetLanguage);
  }
}
