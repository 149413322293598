export interface ICustomLabel {
  matchType: string;
  newLabel: string;
  originalLabel: string;
  id?: number;
}

export enum ECustomLabelType {
  full = 'FULL_MATCH',
  like = 'LIKE_MATCH',
}
