import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import {
  IUserMenu,
  IUserMenuConfigMap,
  IUserMenuConfigSimpleIDMap,
} from '@User/usermenu/usermenu-extras/usermenu.interface';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
export namespace UserMenuStore {
  //#region STORE

  export interface IState {
    userMenu?: IUserMenu;
    userMenuConfigMap?: IUserMenuConfigMap;
    userMenuConfigSimpleIDMap?: IUserMenuConfigSimpleIDMap;
  }

  const initialState: IState = {};
  //#endregion

  //#region ACTIONS
  export const actions = createActionGroup({
    source: 'User Menu API',
    events: {
      setUserMenu: props<{ userMenu: IUserMenu }>(),
      getUserMenuFromLocalSuccess: props<{ userMenu: IUserMenu }>(),
      saveToLocalSuccess: emptyProps(),
      checkForChanges: props<{ usermenuCode: string; updated: string }>(),
      getFromOnline: props<{ usermenuCode?: string }>(),
      getFromOnlineSuccess: props<{ userMenu: IUserMenu }>(),
      // dontGetOnline: emptyProps(),

      setUserMenuMaps: props<{
        userMenuConfigMap: IUserMenuConfigMap;
        userMenuConfigSimpleIDMap: IUserMenuConfigSimpleIDMap;
      }>(),
      generateUserMenuMaps: props<{
        userMenu: IUserMenu;
      }>(),
      generateUserMenuMapsSuccess: props<{
        userMenuConfigMap: IUserMenuConfigMap;
        userMenuConfigSimpleIDMap: IUserMenuConfigSimpleIDMap;
      }>(),
      end: emptyProps(),
    },
  });
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const select = (state: IAppState) => state.userMenu;
    
    export const usermenu = createSelector(select, (state) =>
    
      state.userMenu
        ? {
            userMenuConfig: state.userMenu.userMenuConfig,
            userMenuConfigDetails: state.userMenu?.userMenuConfigDetails?.filter(
              (x) => x.editAccess || x.viewAccess || x['access'],
            ),
          }
        : null,
    );

    export const userMenuConfigSimpleIDMap = createSelector(
      select,
      (state) => state.userMenuConfigSimpleIDMap,
    );

    // export const usermenuCode = createSelector(usermenu, (state) => state?.userMenuConfig?.userMenu);
    // export const userMenuConfigMap = createSelector(select, (state) => state.userMenuConfigMap);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.setUserMenu, (state, { userMenu }) => {
      return { ...state, userMenu };
    }),
    on(actions.setUserMenuMaps, (state, { userMenuConfigMap, userMenuConfigSimpleIDMap }) => {
      // debugger;
      PermissionManager.bUsermenuConfigMap = userMenuConfigMap;
      PermissionManager.bUsermenuConfigSimpleIDMap = userMenuConfigSimpleIDMap;
      return { ...state, userMenuConfigMap, userMenuConfigSimpleIDMap };
    }),
  );
  //#endregion
}
