import { Injectable } from '@angular/core';
import { merge } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { ICodeDescription, ISearchResponse2 } from '@Shared/models/index.model';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ECustomLabelType, ICustomLabel } from './labels.model';
import { Store } from '@ngrx/store';
import { LabelsStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/labels/labels.reducer';

@Injectable({
  providedIn: 'root',
})
export class LabelEndpointsService {
  baseURL = environment.apiBaseUrl + '/rest/page/label/';

  constructor(
    public apiS: ApiService,
    public store: Store,
  ) {}

  getAllLabels = () => {
    return this.apiS
      .get<ISearchResponse2<ICustomLabel>>(`${this.baseURL}search`)
      .pipe(map((r) => r?.content?.sort2('originalLabel', true)));
  };

  submit(entries: ICustomLabel[]) {
    entries.forEach((x) => {
      x.newLabel = x.newLabel.toLowerCase().trim();
      x.originalLabel = x.originalLabel.toLowerCase().trim();
    });
    return this.apiS.post(`${this.baseURL}multiple`, entries).pipe(
      tap(() => {
        this.store.dispatch(LabelsStore.actions.getFromOnline());
      }),
    );
  }
}
