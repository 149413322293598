import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { CacheService } from './cache/cache.service';
import { AppLocalCacheService } from './cache/local-cache.service';

import { ApiService as AS } from 'ets-fe-ng-sdk';
import { Injectable, inject } from '@angular/core';
import { EndorsementResponse } from '../Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import { Observable, catchError, last, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends AS {
  public http = inject(HttpClient);
  public cacheS = inject(CacheService);
  public lCacheS = inject(AppLocalCacheService);
  constructor() {
    super();
  }
  skipLogger(dataLength: number) {
    return dataLength <= 10
      ? null
      : {
          options: { headers: new HttpHeaders().set('skipLogger', 'true') },
        };
  }
  // protected handleRequestError = (err: HttpErrorResponse) => {
  //   // debugger;
  //   let message;
  //   if (err?.error instanceof ArrayBuffer)
  //     message = String.fromCharCode.apply(null, new Uint8Array(err?.error));
  //   return throwError(message || err?.error?.message || err);
  // };
  deleteWithEndorsment<T = any>(route: string) {
    // debugger;
    return this.delete<EndorsementResponse<T>>(route, {
      options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
    }).pipe(map((r) => new EndorsementResponse(r)));
  }
  deleteWithBodyWithEndorsment<T = any>(route: string, body?: any) {
    // debugger;
    return this.deleteWithBody<EndorsementResponse<T>>(route, body, {
      options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
    }).pipe(
      last(),
      map((r) => new EndorsementResponse(r?.body)),
    );
  }
  patchWithEndorsment<T = any>(route: string, body: any) {
    // debugger;
    return this.patch<EndorsementResponse<T>>(route, body, {
      useEndorsement: true,
    }).pipe(map((r) => new EndorsementResponse(r)));
  }
  postWithEndorsment<T = any>(route: string, body: any) {
    // debugger;
    return this.post<EndorsementResponse<T>>(route, body, {
      useEndorsement: true,
    }).pipe(map((r) => new EndorsementResponse(r)));
  }
  putWithEndorsment<T = any>(route: string, body: any) {
    // debugger;
    return this.put<EndorsementResponse<T>>(route, body, {
      options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
    }).pipe(map((r) => new EndorsementResponse(r)));
  }
  getWithProgress = <T = any>(
    route: string,
    parameters?: { [field: string]: string | number | boolean },
  ): Observable<T> => {
    // this.logRoutes('get', route, parameters);
    const query = route + this.getRequestParse(parameters);
    return new Observable<T>((res) => {
      this.http
        .request(
          new HttpRequest('GET', query, {
            reportProgress: true,
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('Access-Control-Allow-Headers', 'Content-Length')
              .set('Access-Control-Expose-Headers', 'Content-Length'),
          }),
        )
        .subscribe({
          next: (r) => {
            console.log(r?.['total']);
            res.next(r as any);
            // res.complete();
          },
          error: (err) => {
            res.error(err);
          },
        });
    });
  };
  // postFile<T = any>(route: string, body: FormData, extras?: any) {
  //   // return this.post<T>(route, body, {
  //   //   options: {
  //   //     responseType: 'json',
  //   //     ...extras?.options,
  //   //     headers: this.concatenateHeaders(
  //   //       new HttpHeaders({
  //   //         enctype: 'multipart/form-data',
  //   //       }),
  //   //       extras?.options?.headers
  //   //     ),
  //   //   },
  //   // });

  //   return this.http
  //     .post(encodeURI(route), body, {
  //       // responseType: 'json',
  //       ...extras?.options,
  //       // headers: this.concatenateHeaders(
  //       //   new HttpHeaders({
  //       //     enctype: 'multipart/form-data',
  //       //   }),
  //       //   extras?.options?.headers
  //       // ),
  //     })
  //     .pipe(catchError(this.handleRequestError)) as Observable<any>;
  // }
}
