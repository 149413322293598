import { MenuItem } from '@Shared/models/IMenuItem';
import { ESystem } from '@Shared/models/index.model';
import { IMiniUserMenuConfigDetail, IUserMenuConfigDetail, IUserMenuConfigSimpleIDMap } from '@User/usermenu/usermenu-extras/usermenu.interface';

export class PermissionManager {
	static pagePermission: IMiniUserMenuConfigDetail & { slug: string };
	static findClosetParentByURL(url: string, menuIndex: Partial<MenuItem>[], routeMenuID?: string): MenuItem {
		// debugger;

		const _closestMatch = menuIndex?.filter((x) => url.startsWith(x.link));
		const closestMatch = _closestMatch?.sortByFieldLength('link')?.pop();
		// console.log(menuIndex.map(x=>x.link))
		if (!closestMatch) return null;
		return (closestMatch.children?.[routeMenuID] as any) || closestMatch;
	}
 
	static findBySlug(slug: string, menu: MenuItem): MenuItem {
		let resp: MenuItem = null;
		if (!menu) return null;
		if (menu.id == slug) return menu;
		if (menu.children[slug]) return menu.children[slug] as any as MenuItem;
		// const recurser = (menu: MenuItem | PageButton) => {
		//   if (!menu) return null;
		//   if (menu.id == slug) return menu;
		//   if (menu.children[slug]) return menu.children[slug];
		//   else
		//     for (const key in menu.children) {
		//       resp = recurser(item as any);
		//       if (resp) return resp;
		//     }
		// };
		// recurser(menu);
		return resp;
	}

	// static pagePermission: MenuItem;
	static bUsermenuConfigMap: {
		[module: string]: {
			[sectorSlug: string]: IUserMenuConfigDetail;
		};
	};
	static bUsermenuConfigSimpleIDMap: IUserMenuConfigSimpleIDMap

	static selectFromComplexMap = (
		menuID: string,
		parentMenuID: string,
		system: ESystem,
	): IMiniUserMenuConfigDetail => {
		if (!menuID) return null;
		console.log('menuitem config query', menuID, parentMenuID, system);
		return parentMenuID
			? this.bUsermenuConfigMap?.[system]?.[this.c2bKeyer({ id: menuID }, parentMenuID)]
			: this.selectFromSimpleMap(menuID);
	};

	static selectFromSimpleMap = (menuID: string) => this.bUsermenuConfigSimpleIDMap?.[menuID];

	static readonly menuConfigIndexStorageKey = 'menuConfigIndexStorageKey';
	static readonly menuConfigSimpleIndexStorageKey = 'menuConfigSimpleIndexStorageKey';
	static readonly mapDelimiter = '~~~';

	static b2cKeyer = (item: IUserMenuConfigDetail) => (item.section || null) + this.mapDelimiter + item.slug;
	static c2bKeyer = (item: { id?: string }, parentId: string) =>
		(parentId || null) + this.mapDelimiter + item.id;

	/**Last route menuitemids found */
	static routeMIDs: string[] = [];
	static lastRouteMID: string;
	static lastParentRouteMID: string;
	static menuLinkToIDIndex: { [link: string]: string } = {};
}
