import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { ICustomLabel } from 'projects/evolutics-shared-lib/src/lib/Customization/customization-pages/labels/labels.model';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
export namespace LabelsStore {
  //#region STORE

  export interface IState extends EntityState<ICustomLabel> {
    cache: { [originalText: string]: string };
  }

  const adapter: EntityAdapter<ICustomLabel> = createEntityAdapter<ICustomLabel>({
    sortComparer: false,
    selectId: (i) => i.id,
  });

  const initialState: IState = {
    ...adapter.getInitialState(),
    cache: {},
  };
  //#endregion

  //#region ACTIONS
  export const actions = createActionGroup({
    source: 'Labels API',
    events: {
      // getListFromLocal: emptyProps(),
      getFromOnline: emptyProps(),
      getListFromOnlineSuccess: props<{ labels: ICustomLabel[] }>(),
      setList: props<{ labels: ICustomLabel[] }>(),
      saveToLocalSuccess: emptyProps(),
      updateCache: props<{ originalText: string; replacementText: string }>(),
    },
  });
  //#endregion

  //#region SELECTORS
  export namespace selectors {
    const select = (state: IAppState) => state.labels;
    export const list = createSelector(select, adapter.getSelectors().selectAll);
    export const total = createSelector(select, adapter.getSelectors().selectTotal);
    export const hasList = createSelector(total, (state) => state);
    export const cache = createSelector(select, (state) => state.cache);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.setList, (state, { labels }) => {
      // debugger;
      return adapter.setAll(labels || [], state);
    }),
    on(actions.updateCache, (state, { originalText, replacementText }) => ({
      ...state,
      cache: { ...state.cache, [originalText]: replacementText },
    })),
  );
  //#endregion
}
