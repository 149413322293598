import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../Services/translation.service';
import { ITranslatorConfig } from 'ets-fe-ng-sdk';

@Pipe({
  name: 'appTranslate',
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(private tS: TranslationService) {}
  transform(text: string | number, config?: ITranslatorConfig) {
    return this.tS.pipeTransformer(text, config);
  }
}
