import { TitleCasePipe } from '@angular/common';
import { Injectable, computed } from '@angular/core';
import { LabelEndpointsService } from '../../Customization/customization-pages/labels/label-endpoints.service';
import { ECustomLabelType, ICustomLabel } from '../../Customization/customization-pages/labels/labels.model';
import { StorageService } from '../storage.service';
import { Store } from '@ngrx/store';
import { LabelsStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/labels/labels.reducer';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  readonly labels = this.store.selectSignal(LabelsStore.selectors.list);
  readonly hasList = this.store.selectSignal(LabelsStore.selectors.hasList);
  readonly cache = this.store.selectSignal(LabelsStore.selectors.cache);
  readonly labelMaps = computed<ILabelMap>(() => {
    const list = this.labels();
    return this.formMaps(list);
  });
  private readonly storageKey: string = 'customLabels';

  constructor(
    public labelEndpointS: LabelEndpointsService,
    public storageS: StorageService,
    public store: Store,
    public titlePipe: TitleCasePipe,
  ) {}

  protected formMaps = (labels: ICustomLabel[]): ILabelMap => {
    const labelMaps: ILabelMap = { fullMatchLabelsMap: {}, partialMatchLabelsMap: {} };
    const labelTypes: {
      map: keyof typeof labelMaps;
      type: ECustomLabelType;
    }[] = [
      { map: 'fullMatchLabelsMap', type: ECustomLabelType.full },
      {
        map: 'partialMatchLabelsMap',
        type: ECustomLabelType.like,
      },
    ];
    const formatters: ((val: string) => string)[] = [
      (val) => val,
      (val) => val?.toLowerCase(),
      (val) => val?.toUpperCase(),
      (val) => this.titlePipe.transform(val),
    ];
    labelTypes.forEach((type) => {
      labelMaps[type.map] = {};
      const filteredLabels = labels.filter((x) => x.matchType == type.type);
      filteredLabels.forEach((label) => {
        const originalLabel = label.originalLabel?.trim();
        const newLabel = label.newLabel?.trim();
        for (const func of formatters) {
          labelMaps[type.map][func(originalLabel)] = {
            ...label,
            originalLabel: func(originalLabel),
            newLabel: func(newLabel),
          };
        }
      });
    });
    return labelMaps;
    // debugger;
  };

  getAllLabels = () => this.labelEndpointS.getAllLabels();

  saveToLocal = (labels: ICustomLabel[]) => this.storageS.saveItem(this.storageKey, labels);

  getFromLocal = () => this.storageS.getItem$<ICustomLabel[]>(this.storageKey);

  replaceLabel = (originalText: string) => {
    // debugger
    if (!this.hasList()) return originalText;
    // if (originalText == 'National Insurance Number') debugger;
    const cache = this.cache();
    if (cache[originalText] !== undefined) return cache[originalText] || originalText;

    const labelMaps = this.labelMaps();
    if (!originalText) return null;
    if (!labelMaps.fullMatchLabelsMap || !labelMaps.fullMatchLabelsMap) return originalText;
    let custom = labelMaps.fullMatchLabelsMap[originalText.trim()];
    if (custom) {
      this.store.dispatch(
        LabelsStore.actions.updateCache({ originalText, replacementText: custom.newLabel }),
      );
      return custom.newLabel;
    } else {
      custom =
        labelMaps.partialMatchLabelsMap[
          Object.keys(labelMaps.partialMatchLabelsMap).find((x) => originalText.trim().includes(x))
        ];

      const replacementText = custom ? originalText.split(custom.originalLabel).join(custom.newLabel) : null;
      this.store.dispatch(LabelsStore.actions.updateCache({ originalText, replacementText }));
      return replacementText;
    }
    // debugger;
    // if (custom) {
    //   // debugger;
    //   return this.replaceWordInSentence(originalText, custom.originalLabel,custom.newLabel);
    // let newText = '';
    // const chunks = originalText.toLowerCase().split(custom.originalLabel);
    // const originalChunkLength = custom.originalLabel.length;
    // let lastIndex = 0;
    // for (let index = 0; index < chunks.length; index++) {
    //   const chunk = chunks[index];
    //   debugger;
    //   const startIndex = lastIndex * originalChunkLength,
    //     endIndex = startIndex + chunk.length;
    //   lastIndex = endIndex;
    //   newText += originalText.substr(startIndex, chunk.length);
    // }
    // return newText;
    // return originalText
    //   .toLowerCase()
    //   .split(custom.originalLabel)
    //   .join(custom.newLabel);
    // }
  };

  /**
   * Replace a word in the sentence  with a new word in the original case of the sentence
   * @param sentence
   * @param currentWord
   * @param newWord
   * @returns
   */
  replaceWordInSentence = (sentence: string, currentWord: string, newWord: string) => {
    return sentence.split(currentWord).join(newWord);
    // const wordIndex = sentence.toLowerCase().indexOf(word);
    // if (wordIndex < 0) return sentence;
    // const newWord = sentence.substr(wordIndex, word.length);
    // return sentence.replace(newWord, word);
  };
}
export interface ILabelMap {
  fullMatchLabelsMap: { [label: string]: ICustomLabel };
  partialMatchLabelsMap: { [label: string]: ICustomLabel };
}
