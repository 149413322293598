import { Injectable, computed, effect, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ESystem, ESystemBusLine, IValueLabel } from '../Shared/models/index.model';
import { MenuItem } from '../Shared/models/IMenuItem';
import { TranslationService } from './translation.service';
import { AppService as ETSAppService } from 'ets-fe-ng-sdk';
import { Store } from '@ngrx/store';
import { UserMenuStore } from 'projects/evolutics-shared-lib/src/lib/@ngrx/usermenu/usermenu.reducer';
@Injectable({
  providedIn: 'root',
})
export class AppService extends ETSAppService<ESystem, ESystemBusLine, MenuItem> {
  topMenu = environment.menus;
  readonly userMenuConfigSimpleIDMap = this.store.selectSignal(
    UserMenuStore.selectors.userMenuConfigSimpleIDMap,
  );
  currentTopMenuSignal = signal<MenuItem>(this.topMenu?.[0]);

  readonly computedTopMenu = computed(() => {
    const tm = environment.menus,
      userMenuConfigSimpleIDMap = this.userMenuConfigSimpleIDMap();
    // debugger;
    return this.filterMenuArray(tm, userMenuConfigSimpleIDMap);
  });

  readonly computedBottomMenus = computed(() => {
    const tm = this.computedTopMenu();
    // debugger;

    return tm.map((m) => ({ parentID: m.id, subs: this.filterMenuArray(m.subs) })).toMap('parentID');
  });

  readonly currentBottomMenu = computed(() => {
    const computedBottomMenus = this.computedBottomMenus(),
      currentTopMenuSignal = this.currentTopMenuSignal();
    return computedBottomMenus[currentTopMenuSignal.id]?.subs;
  });

  get getFirstAvailableSystem() {
    return this.systemMetadataMap.get(
      environment.activeSystems?.length ? environment.activeSystems[0] : ESystem.life,
    );
  }
  get getCurrentSystemMetadata() {
    return this.systemMetadataMap.get(this.system);
  }
  set currentTopMenu(value: MenuItem) {
    super.currentTopMenu = value;
    this.currentTopMenuSignal.set(value);
  }

  constructor(
    public titleS: Title,
    public translator: TranslationService,
    public store: Store,
  ) {
    super(titleS, translator);
    // effect(() => {
    //   console.log('currentBottomMenu', this.currentBottomMenu());
    // });
  }
  filterMenuArray = (arr: MenuItem[], map = this.userMenuConfigSimpleIDMap()) => {
    return !arr
      ? []
      : environment.useUserMenu && map
        ? arr.filter((x) => !x.hidden && map[x.id]?.viewAccess)
        : arr.filter((x) => !x.hidden);
  };

  busLines: IValueLabel<ESystemBusLine>[] = [
    { value: ESystemBusLine.actuarial, label: 'Actuarial' },
    { value: ESystemBusLine.analytics, label: 'Analytics' },
    { value: ESystemBusLine.assets, label: 'Assets' },
    { value: ESystemBusLine.cash, label: 'Cash' },
    { value: ESystemBusLine.crm, label: 'CRM' },
    { value: ESystemBusLine.dBCloner, label: 'DB Cloner' },
    { value: ESystemBusLine.document, label: 'Document' },
    { value: ESystemBusLine.finance, label: 'Finance' },
    { value: ESystemBusLine.general, label: 'General' },
    { value: ESystemBusLine.health, label: 'Health' },
    { value: ESystemBusLine.human, label: 'Human' },
    { value: ESystemBusLine.life, label: 'Life' },
  ];
}
