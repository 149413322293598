import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EPageType } from '../../models/index.model';
import { PageTemplateComponent } from '../page-template/page-template.component';
import { PageToComponentComponent } from './page-to-component.component';
import { PageToComponentService as PTCS } from 'ets-fe-ng-sdk';
import { Environment } from '@Shared/models/environment.model';

@Injectable({
  providedIn: 'root',
})
export class PageToComponentService extends PTCS {
  /**
   * Opens a component as a modal
   * @param component Component to be opened
   * @param title Value to be set as the header of the modal
   * @param modelData Data to pass into the component as variable `modelData`
   * @param pageType Page type to be set on the component
   * @param modalConfig Modal configuration object to be passed to `MatDialog`
   * @returns The `MatDialog.afterClosed` Observable
   */
  pageToModalOpener<ModalData, T extends PageTemplateComponent<ModalData>>(
    component: Type<any>,
    title: string,
    modelData?: ModalData,
    pageType: EPageType = EPageType.createPage,
    modalConfig?: MatDialogConfig,
  ) {
    return this.dialog
      .open(PageToComponentComponent, {
        data: { component, modelData, title, pageType },
        height: '100vh',
        maxHeight: '100vh',
        width: '100%',
        maxWidth: '100%',
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'fullscreen-modal',
        autoFocus: false,
        ...modalConfig,
      })
      .afterClosed();
  }
}
