import { ESystem } from '@Shared/models/index.model';
import { IMenuItem } from '@Shared/models/IMenuItem';

export namespace PageChildren {
  enum eChildren {
    assets = 'assets',
    accounts = 'accounts',
    calendar = 'calendar',
    clone = 'clone',
    commissions = 'commissions',
    create = 'create',
    creditNotes = 'creditNotes',
    documents = 'documents',
    edit = 'edit',
    endorsements = 'endorsements',
    futureActions = 'futureActions',
    loan = 'loan',
    notes = 'notes',
    pendingQuotes = 'pendingQuotes',
    policies = 'policies',
    production = 'production',
    view = 'view',
    webLogIn = 'webLogIn',
    workflows = 'workflows',
  }

  enum eAgentChildren {
    viewAgent = 'viewAgent',
  }
  export const EAgentChildren = { ...eChildren, ...eAgentChildren };
  export function agentChildren(system?: ESystem) {
    const btns: { [key in keyof typeof EAgentChildren]?: IMenuItem } = {
      viewAgent: {
        label: 'View Agent',
        isPage: true,
        children: {
          accounts: { label: 'Accounts' },
          calendar: { label: 'Calendar' },
          commissions: { label: `Commissions` },
          creditNotes: { label: `Credit notes` },
          documents: { label: `Documents` },
          endorsements: { label: `Endorsements` },
          futureActions: { label: 'Future Actions' },
          loan: { label: `Agent Loan` },
          notes: { label: `Notes` },
          pendingQuotes: { label: `Pending quotes` },
          policies: { label: `Policies` },
          production: { label: `Productions` },
          webLogIn: { label: `Web log in` },
          workflows: { label: `Workflows` },
        },
      },
    };

    return btns;
  }

  enum eAssetChildren {}
  export const EAssetChildren = { ...eChildren, ...eAssetChildren };
  export function assetChildren(system?: ESystem) {
    const btns: { [k in keyof typeof EAssetChildren]?: IMenuItem } = {};

    return btns;
  }

  enum eClientChildren {
    underwritingEvents = 'underwritingEvents',
    relationships = 'relationships',
    businesses = 'businesses',
    pendingPayments = 'pendingPayments',
    viewClient = 'viewClient',
  }
  export const EClientChildren = { ...eChildren, ...eClientChildren };
  export function clientChildrenLite(system?: ESystem) {
    const btns: { [k in keyof typeof EClientChildren]?: IMenuItem } = {
      viewClient: {
        label: 'View Client',
        children: {
          assets: { label: 'Assets' },
          accounts: { label: 'Accounts' },
          businesses: { label: `Businesses` },
          calendar: { label: 'Calendar' },
          documents: { label: `Documents` },
          endorsements: { label: `Endorsements` },
          futureActions: { label: 'Future Actions' },
          notes: { label: `Notes` },
          pendingPayments: { label: `Pending Payments` },
          pendingQuotes: { label: `Pending quotes` },
          policies: { label: `Policies` },
          relationships: { label: `Relationships` },
          underwritingEvents: { label: 'Underwriting Events' },
          webLogIn: { label: `Web log in` },
          workflows: { label: `Workflows` },
        },
      },
    };

    return btns;
  }
  export function clientChildren(system?: ESystem) {
    const btns: { [k in keyof typeof EClientChildren]?: IMenuItem } = {
      ...clientChildrenLite(system),
      ...providerChildren(system),
    };

    return btns;
  }

  export interface IDocumentChildren {}
  export function documentChildren(system?: ESystem) {
    const btns: { [k in keyof IDocumentChildren]?: IMenuItem } = {};

    return btns;
  }

  enum eGroupChildren {
    convertToPolicy = 'convertToPolicy',
    reconcileSuspense = 'reconcileSuspense',
    members = 'members',
    assets = 'assets',
    renewal = 'renewal',
    viewGroup = 'viewGroup',
  }
  export const EGroupChildren = { ...eGroupChildren, ...PageChildren.EPolicyChildren };
  export function groupChildren(system?: ESystem) {
    const btns: { [k in keyof typeof EGroupChildren]?: IMenuItem } = {
      viewGroup: {
        label: 'View Group',
        children: {
          accounts: { label: 'Accounts' },
          addRider: { label: 'Add Rider' },
          assets: { label: 'Assets' },
          authorisePolicy: { label: 'Authorise Policy' },
          commissions: { label: 'Commissions' },
          convertToPolicy: { label: 'Convert To Policy' },
          covers: { label: 'Covers' },
          creditNoteDocuments: { label: 'Credit Note Documents' },
          documents: { label: `Documents` },
          endorsements: { label: 'Endorsements' },
          financialNote: { label: 'Financial Note' },
          futureActions: { label: 'Future Actions' },
          members: { label: 'Members' },
          notes: { label: `Notes` },
          pendingPaymentsOutward: { label: 'Pending Payments Outward' },
          perils: { label: 'Perils' },
          policyLoan: { label: 'Policy Loan' },
          pricing: { label: 'Pricing' },
          reconcileSuspense: { label: 'Reconcile Suspense' },
          reinsurance: { label: 'Reinsurance' },
          relationships: { label: 'Relationships' },
          renewal: { label: 'Renewal' },
          scheduledPayments: { label: 'Scheduled Payments' },
          totalPaymentsOutward: { label: 'Total Payments Outward' },
          totalPaymentsReceived: { label: 'Total Payments Received' },
          uncoveredSAR: { label: 'Uncovered Sar' },
          underwriting: { label: 'Underwriting' },
          workflows: { label: 'Workflows' },
        },
      },
    };

    return btns;
  }

  export interface IPaymentChildren {}
  export function paymentChildren(system?: ESystem) {
    const btns: { [k in keyof IPaymentChildren]?: IMenuItem } = {};

    return btns;
  }

  enum ePolicyChildren {
    accounts = 'accounts',
    addRider = 'addRider',
    authorisePolicy = 'authorisePolicy',
    commissions = 'commissions',
    covers = 'covers',
    creditNoteDocuments = 'creditNoteDocuments',
    financialNote = 'financialNote',
    pendingPaymentsOutward = 'pendingPaymentsOutward',
    perils = 'perils',
    policyLoan = 'policyLoan',
    pricing = 'pricing',
    reinsurance = 'reinsurance',
    relationships = 'relationships',
    scheduledPayments = 'scheduledPayments',
    totalPaymentsOutward = 'totalPaymentsOutward',
    totalPaymentsReceived = 'totalPaymentsReceived',
    totalPaymentsReceivedStatement = 'totalPaymentsReceivedStatement',
    uncoveredSAR = 'uncoveredSAR',
    underwriting = 'underwriting',
    viewPolicy = 'viewPolicy',
  }
  export const EPolicyChildren = { ...eChildren, ...ePolicyChildren };
  export function policyChildren(system?: ESystem) {
    const btns: { [key in keyof typeof EPolicyChildren]?: IMenuItem } = {
      viewPolicy: {
        label: 'View Policy',
        children: {
          accounts: { label: 'Accounts' },
          addRider: { label: 'Add Rider' },
          authorisePolicy: { label: 'Authorise Policy' },
          commissions: { label: 'Commissions' },
          covers: { label: 'Covers' },
          creditNoteDocuments: { label: 'Credit Note Documents' },
          documents: { label: `Documents` },
          endorsements: { label: 'Endorsements' },
          financialNote: { label: 'Financial Note' },
          futureActions: { label: 'Future Actions' },
          notes: { label: `Notes` },
          pendingPaymentsOutward: { label: 'Pending Payments Outward' },
          perils: { label: 'Perils' },
          policyLoan: { label: 'Policy Loan' },
          pricing: { label: 'Pricing' },
          reinsurance: { label: 'Reinsurance' },
          relationships: { label: 'Relationships' },
          scheduledPayments: { label: 'Scheduled Payments' },
          totalPaymentsOutward: { label: 'Total Payments Outward' },
          totalPaymentsReceived: { label: 'Total Payments Received' },
          totalPaymentsReceivedStatement: { label: 'Total Payments Received Statement' },
          uncoveredSAR: { label: 'Uncovered Sar' },
          underwriting: { label: 'Underwriting' },
          workflows: { label: 'Workflows' },
        },
      },
    };
    return btns;
  }

  enum eProviderChildren {
    accounts = 'accounts',
    branches = 'branches',
    contactPersons = 'contactPersons',
    disciplines = 'disciplines',
    license = 'license',
    payees = 'payees',
    pendingPayments = 'pendingPayments',
    products = 'products',
    tariffs = 'tariffs',
    viewProvider = 'viewProvider',
  }
  export const EProviderChildren = { ...eChildren, ...eProviderChildren };
  export function providerChildren(system?: ESystem) {
    const btns: { [k in keyof typeof EProviderChildren]?: IMenuItem } = {
      viewProvider: {
        label: 'View Provider',
        children: {
          accounts: { label: 'Accounts' },
          branches: { label: 'Branches' },
          calendar: { label: 'Calendar' },
          contactPersons: { label: 'Contact Persons' },
          disciplines: { label: 'Disciplines' },
          documents: { label: `Documents` },
          endorsements: { label: `Endorsements` },
          futureActions: { label: 'Future Actions' },
          license: { label: 'License' },
          notes: { label: `Notes` },
          payees: { label: 'Payees' },
          pendingPayments: { label: `Pending Payments` },
          pendingQuotes: { label: `Pending quotes` },
          policies: { label: `Policies` },
          products: { label: 'Products' },
          tariffs: { label: 'Tariffs' },
          webLogIn: { label: `Web log in` },
          workflows: { label: `Workflows` },
        },
      },
    };

    return btns;
  }

  enum eQuotationChildren {
    annuityPayment = 'annuityPayment',
    authorizeQuote = 'authorizeQuote',
    conversionProbability = 'conversionProbability',
    convertToPolicy = 'convertToPolicy',
    daysToExpiry = 'daysToExpiry',
    financialNote = 'financialNote',
    generate = 'generate',
    iterations = 'iterations',
    payment = 'payment',
    totalPeriodicPremium = 'totalPeriodicPremium',
    viewQuotation = 'viewQuotation',
  }
  export const EQuotationChildren = { ...eChildren, ...ePolicyChildren, ...eQuotationChildren };
  export function quotationChildren(system?: ESystem) {
    const btns: { [k in keyof typeof EQuotationChildren]?: IMenuItem } = {
      viewQuotation: {
        label: 'View Quotation',
        children: {
          accounts: { label: 'Accounts' },
          addRider: { label: 'Add Rider' },
          annuityPayment: { label: 'Annuity Payment' },
          authorizeQuote: { label: 'Authorise Quote' },
          commissions: { label: 'Commissions' },
          convertToPolicy: { label: 'Convert To Policy' },
          conversionProbability: { label: 'Conversion Probability' },
          covers: { label: 'Covers' },
          creditNoteDocuments: { label: 'Credit Note Documents' },
          daysToExpiry: { label: 'Days To Expiry' },
          documents: { label: `Documents` },
          endorsements: { label: 'Endorsements' },
          financialNote: { label: 'Financial Note' },
          futureActions: { label: 'Future Actions' },
          generate: { label: 'Generate' },
          iterations: { label: 'Iterations' },
          notes: { label: `Notes` },
          payment: { label: 'Payment' },
          pendingPaymentsOutward: { label: 'Pending Payments Outward' },
          perils: { label: 'Perils' },
          policyLoan: { label: 'Policy Loan' },
          pricing: { label: 'Pricing' },
          reinsurance: { label: 'Reinsurance' },
          relationships: { label: 'Relationships' },
          scheduledPayments: { label: 'Scheduled Payments' },
          totalPeriodicPremium: { label: 'Total Periodic Premium' },
          uncoveredSAR: { label: 'Uncovered Sar' },
          underwriting: { label: 'Underwriting' },
          workflows: { label: 'Workflows' },
        },
      },
    };

    return btns;
  }

  enum eReceiptChildren {}
  export const EReceiptChildren = { ...eChildren, ...eReceiptChildren };
  export function receiptChildren(system?: ESystem) {
    const btns: { [key in keyof typeof EReceiptChildren]?: IMenuItem } = {};
    return btns;
  }

  enum eReportChildren {}
  export const EReportChildren = { ...eChildren, ...eReportChildren };
  export function reportChildren(system?: ESystem) {
    const btns: { [key in keyof typeof EReportChildren]?: IMenuItem } = {};
    return btns;
  }

  enum eWorkflowChildren {
    comment = 'comment',
    history = 'history',
    escalate = 'escalate',
    error = 'error',
    checklist = 'checklist',
    authorize = 'authorize',
    reassign = 'reassign',
    link = 'link',
    outstandingSnoozeDuration = 'outstandingSnoozeDuration',
    relatedActiveTasks = 'relatedActiveTasks',
    viewWorkflow = 'viewWorkflow',
  }
  export const EWorkflowChildren = { ...eChildren, ...eWorkflowChildren };
  export function workflowChildren(system?: ESystem) {
    const btns: { [k in keyof typeof EWorkflowChildren]?: IMenuItem } = {
      viewWorkflow: {
        label: 'View Workflow',
        children: {
          authorize: { label: 'Authorize' },
          checklist: { label: 'Checklist' },
          comment: { label: 'Comment' },
          documents: { label: `Documents` },
          error: { label: 'Error' },
          escalate: { label: 'Escalate' },
          history: { label: 'History' },
          link: { label: 'Link' },
          notes: { label: `Notes` },
          outstandingSnoozeDuration: { label: 'Outstanding Snooze Duration' },
          reassign: { label: 'Reassign' },
          relatedActiveTasks: { label: 'Related Active Tasks' },
        },
      },
    };

    return btns;
  }
}
