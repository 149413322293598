import { ComponentType } from '@angular/cdk/portal';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  CellType,
  IconType as ETSIconType,
  TableCol as ETSTableCol,
  IRowOption as ETSIRowOption,
  SortType,
} from 'ets-fe-ng-sdk';

export {
  FCInput,
  Btn,
  BtnLg,
  CellType,
  Constant,
  CustomValidationError,
  Day,
  ELanguage,
  EMenuLocation,
  EMenuType,
  EPageType,
  CETSInput as CInput,
  ETSFKVP as FKVP,
  InputType,
  ETSKVP as KVP,
  EValidationType,
  IBtn,
  BtnType,
  BtnGroup,
  IKVP,
  ICode,
  ICodeDescription,
  ICodeTitle,
  ILbl,
  Lbl,
  IStrictFormGroup,
  IConfigImages,
  IDocMetadata,
  IGetQuery,
  ImageType,
  IMktDescription,
  ISearchResponse,
  ISearchResponse2,
  IServiceFunction,
  ISystem,
  ITab,
  ITableCol,
  ITypeDescription,
  IValidationMessage,
  IValueLabel,
  SortType,
} from 'ets-fe-ng-sdk';

export type IconType =
  | ETSIconType
  | 'play'
  | 'percent'
  | 'cancel'
  | 'refresh'
  | 'dropdown'
  | 'error'
  | 'shake'
  | 'table'
  | 'spoon';
export type InputSubType = 'money';
//#region NON SDK
export type RefCat =
  | 'AGT'
  | 'LH'
  | 'APP'
  | 'ASS'
  | 'AUTH'
  | 'BAN'
  | 'BAT'
  | 'BRA'
  | 'CLA'
  | 'CLI'
  | 'COM'
  | 'COU'
  | 'COV'
  | 'DN'
  | 'DOC'
  | 'END'
  | 'ENR'
  | 'EXP'
  | 'GRO'
  | 'NB'
  | 'PAY'
  | 'PNS'
  | 'PO'
  | 'POL'
  | 'PRO'
  | 'QUO'
  | 'RAT'
  | 'SN'
  | 'ST'
  | 'TAR'
  | 'TAS'
  | 'TRA'
  | 'TSC'
  | 'USR'
  | 'WF';
export type SubCategory =
  | 'PP'
  | 'PF'
  | 'SI'
  | 'ID'
  | 'AOC'
  | 'CN'
  | 'AP'
  | 'LI'
  | 'RIB'
  | 'LIC'
  | 'AGT'
  | 'UWR';
export enum ESystem {
  actuarial = 'actuarial',
  analytics = 'analytics',
  assets = 'assets',
  cash = 'receipt',
  erm = 'erm',
  crm = 'crm',
  dBCloner = 'dbcloner',
  finance = 'finance',
  general = 'general',
  health = 'health',
  human = 'human',
  life = 'life',
  process = 'process',

  home = 'home',
  teams = 'teams',
  sales = 'sales',
  admin = 'admin',
  report = 'report',
  client = 'client',
  calendar = 'calendar',
  workflow = 'workflow',
}

export enum ESubSystem {
  client = 'client',
  agent = 'agent',
  quotation = 'quotation',
  policy = 'policy',
  payment = 'payment',
  report = 'report',
  admin = 'admin',
  document = 'document',
  setup = 'setup',
  group = 'group',
}
export enum ESystemBusLine {
  actuarial = 'A',
  analytics = 'analytics',
  assets = 'assets',
  cash = 'receipt',
  crm = 'C',
  dBCloner = 'dbcloner',
  document = 'D',
  finance = 'F',
  general = 'G',
  health = 'H',
  human = 'human',
  life = 'L',
  process = 'P',
}
//#endregion

export enum EDownloadType {
  csv = 'csv',
  pdf = 'pdf',
}

export class TableCol<T = any> extends ETSTableCol<T> {
  sticky?: boolean;
  noFormat?: boolean;
  constructor(
    t: string,
    f?: keyof T,
    formatter?: (val: any) => string | Promise<string>,
    formatterRow?: FormatterFuncType,
    type?: CellType,
    checked?: boolean,
    routeFormatter?: FormatterFuncType<string, T>,
    mqueryParams?: FormatterFuncType<{}, T>,
    action?: (row: any, cellField: keyof T) => any,
  ) {
    super(t, f, formatter, formatterRow, type, checked, routeFormatter, mqueryParams, action);
  }
}
declare type FormatterFuncType<R = string, T = any> = (row: T, cellField?: keyof T) => R;

export interface INotification {
  id: number;
  title: string;
  body: string;
  /**
   * Array of userids
   */
  assignedTo: string[];
  /**
   * array of usergroups
   */
  assignedToGroup: string[];
  deliveryStatus: 'SENT' | 'UNSENT';
  notificationStatus: 'ACTIVE' | 'INACTIVE';
  /**
   * Stringified action object
   */
  misc: string;
  refNo: string;
  refCat: string;
  dateCreated: string;
  dateSent: string;
}

export type IFormDataIndex<T = any> = {
  [field in keyof T]: IFormData | IFormDataIndex<T>[];
};

export interface IFormData {
  label?: string;
  value?: string | number | boolean;
  formattedValue?: string;
}

export type MapFunction<T = any> = (value: T, index?: number, array?: T[]) => boolean;

export type TypeOf =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'object'
  | 'function';

export interface IRowOption<T = any> extends ETSIRowOption<T> {
  iconClass?: string;
  itemClass?: string;
}

export type Status = 'COMPLETED' | 'PENDING' | 'FAILED';

export interface INumberFullname {
  fullName: string;
  no: string;
}
export interface IModalConfig<TComponent> {
  component: ComponentType<TComponent>;
  config: MatDialogConfig;
  valueCB: (val?: any) => any;
  closeCB: () => any;
}
export interface ITableName {
  schema: string;
  _label?: string;
  primaryTable: string;
}
