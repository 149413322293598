import { Component, Inject, OnInit, Type, ViewChild } from '@angular/core';
import { PageToComponentDirective } from './page-to-component.directive';
import { ModalFormLayoutComponent } from '../../../Layout/modal-form-layout/modal-form-layout.component';
import { PageToComponentComponent as PTCC, PageModal as PM, EPageType } from 'ets-fe-ng-sdk';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageTemplateComponent } from '../page-template/page-template.component';

@Component({
  templateUrl: './page-to-component.component.html',
  styleUrls: ['./page-to-component.component.scss'],
  standalone: true,
  imports: [ModalFormLayoutComponent, PageToComponentDirective],
})
export class PageToComponentComponent extends PTCC implements OnInit {
  @ViewChild(PageToComponentDirective, { static: true })
  compHost!: PageToComponentDirective;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: Type<PageTemplateComponent>;
      modelData: any;
      title: string;
      pageType: EPageType;
    },
    public dialogRef: MatDialogRef<PageToComponentComponent>,
  ) {
    super(data,dialogRef)
  }
  ngOnInit(): void {
    return super.ngOnInit();
  }
}

@Component({ template: `` })
export class PageModal<ModelData = any, CompletionFunc = any> extends PM<ModelData, CompletionFunc> {}
