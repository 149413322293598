import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'watermark',
    templateUrl: './watermark.component.html',
    styleUrls: ['./watermark.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class WatermarkComponent implements OnInit {
img=environment.images.watermark
  constructor() { }

  ngOnInit(): void {
  }

}
