import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { IInfoDialogBtn, ITableCol, ITextCase2 } from 'ets-fe-ng-sdk';
import { IGetQuery, InputType } from '@Shared/models/index.model';
import { fieldToLabelMap } from '@configs/field-to-label-map.config';

export class EndorsementResponse<T = any> {
  code: usedEndorsement;
  data: T;
  endorsementNo: string;
  message: string;
  usedEndorsement: boolean;
  constructor(interfce: EndorsementResponse<T>) {
    Object.assign(this, interfce);
    this.usedEndorsement = interfce?.code == '00';
  }
}
type usedEndorsement = '01' | '00';
export namespace EndorsementFuncs {
  /**
   *Used to generate a success message for an endorsement response object
   * @param endorsement The endorsement object
   * @param prefix The prefix for the message (excluding the preceding space character)
   * @param suffix The suffix for the message (excluding the preceding space character)
   * @returns The generated success message
   */
  export function successMessage<T>(endorsement: EndorsementResponse<T>, prefix: string, suffix?: string) {
    return (
      (endorsement.usedEndorsement
        ? `${prefix} has been submitted for authorization\nEndorsement Number: ${endorsement.endorsementNo}`
        : `${prefix} has been saved successfully`) + (suffix ? `\n${suffix}` : '')
    );
  }
  export function successDeletionMessage<T>(
    endorsement: EndorsementResponse<T>,
    prefix: string,
    suffix?: string,
  ) {
    return (
      (endorsement.usedEndorsement
        ? `${prefix} has been submitted for authorization\nEndorsement Number: ${endorsement.endorsementNo}`
        : `${prefix} has been deleted successfully`) + (suffix ? `\n${suffix}` : '')
    );
  }
}
export const EEndorseStatus = {
  approved: 'APPROVED',
  declined: 'DECLINED',
  pending: 'PENDING',
} as const;
export type EEndorseStatus = (typeof EEndorseStatus)[keyof typeof EEndorseStatus];

export enum EEndorseCat {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  CLAIM = 'CLAIM',
  CLIENT = 'CLIENT',
  COMPANY = 'COMPANY',
  FINANCE = 'FINANCE',
  PAYMENT = 'PAYMENT',
  PAYOUT = 'PAYOUT',
  POLICY = 'POLICY',
  PRODUCT = 'PRODUCT',
  PROVIDER = 'PROVIDER',
  RATE = 'RATE',
  SERVICING_AGENT = 'SERVICING_AGENT',
  SETUP = 'SETUP',
  TREATY = 'TREATY',
  USER = 'USER',
  WORKFLOW = 'WORKFLOW',
}
export function getEndorsementCats() {
  return Object.keys(EEndorseCat)
    .map((key) => EEndorseCat[key])
    .sort();
}

export enum EEndorseSubCat {
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNT_JOURNAL = 'ACCOUNT_JOURNAL',
  ADJ_PAYOUT = 'ADJ_PAYOUT',
  ADVANCE = 'ADVANCE',
  AGENT = 'AGENT',
  AGENT_BRANCH = 'AGENT_BRANCH',
  AGENT_HIERARCHY = 'AGENT_HIERARCHY',
  AGENT_LICENSE = 'AGENT_LICENSE',
  AGENT_LOAN_TYPE = 'AGENT_LOAN_TYPE',
  AGENT_PAYMENT = 'AGENT_PAYMENT',
  AGENT_PROD_PERMISSION = 'AGENT_PROD_PERMISSION',
  AGENT_SETUP_TYPE = 'AGENT_SETUP_TYPE',
  AGENT_TYPE = 'AGENT_TYPE',
  APPRAISAL_COY = 'APPRAISAL_COY',
  APPRAISAL_CRITERIA = 'APPRAISAL_CRITERIA',
  APPRAISAL_DIV = 'APPRAISAL_DIV',
  APPRAISAL_IND_SETUP = 'APPRAISAL_IND_SETUP',
  APPRAISAL_SETUP_TEAM = 'APPRAISAL_SETUP_TEAM',
  APPRAISAL_SUBDIV = 'APPRAISAL_SUBDIV',
  AUTO_SETTLE_COVERS = 'AUTO_SETTLE_COVERS',
  AUTO_TERMINATE = 'AUTO_TERMINATE',
  BANK_PAYOUT = 'BANK_PAYOUT',
  BANK_PAYOUT_DETAILS = 'BANK_PAYOUT_DETAILS',
  BATCH_SETUP = 'BATCH_SETUP',
  BENEFITS_ESCAL = 'BENEFITS_ESCAL',
  BONUS_RATE = 'BONUS_RATE',
  BONUS_RATE_VALUE = 'BONUS_RATE_VALUE',
  CLAIM_ADJUSTMENT = 'CLAIM_ADJUSTMENT',
  CLIENT = 'CLIENT',
  CLIENT_BANKS = 'CLIENT_BANKS',
  CLIENT_CONTACT = 'CLIENT_CONTACT',
  CLIENT_DATES = 'CLIENT_DATES',
  CLIENT_DETAILS = 'CLIENT_DETAILS',
  CLIENT_EMPLOYMENTS = 'CLIENT_EMPLOYMENTS',
  CLIENT_HEALTH = 'CLIENT_HEALTH',
  CLIENT_HEALTH_PRE_EXIST = 'CLIENT_HEALTH_PRE_EXIST',
  CLIENT_KYC = 'CLIENT_KYC',
  CLIENT_PROVIDER = 'CLIENT_PROVIDER',
  CLIENT_PROVIDER_BRANCHES = 'CLIENT_PROVIDER_BRANCHES',
  CLIENT_PROVIDER_DISCIPLINE = 'CLIENT_PROVIDER_DISCIPLINE',
  CLIENT_PROVIDER_LICENSE = 'CLIENT_PROVIDER_LICENSE',
  CLIENT_PROVIDER_PAYMENT = 'CLIENT_PROVIDER_PAYMENT',
  CLIENT_RELATED_CLIENTS = 'CLIENT_RELATED_CLIENTS',
  CLIENT_STATUS = 'CLIENT_STATUS',
  CLIENT_TAX = 'CLIENT_TAX',
  CODE_NUMBERING_SETUP = 'CODE_NUMBERING_SETUP',
  COMMISSION_ADJUSTMENT = 'COMMISSION_ADJUSTMENT',
  COMMISSION_POSTING = 'COMMISSION_POSTING',
  COMMTYPE = 'COMMTYPE',
  CONTROL_PAYOUT = 'CONTROL_PAYOUT',
  CORPORATE_CLIENT = 'CORPORATE_CLIENT',
  CORRESPONDENCE = 'CORRESPONDENCE',
  COVERS_ACCT = 'COVERS_ACCT',
  COVERS_ANNUITIES_ESCAL = 'COVERS_ANNUITIES_ESCAL',
  COVERS_ANNUITIES_GUAR = 'COVERS_ANNUITIES_GUAR',
  COVERS_ANNUITY = 'COVERS_ANNUITY',
  COVERS_BENEFIT_CLAIM = 'COVERS_BENEFIT_CLAIM',
  COVERS_BENEFIT_SCHEDULE = 'COVERS_BENEFIT_SCHEDULE',
  COVERS_BONUSES = 'COVERS_BONUSES',
  COVERS_COMMISSION_RATE = 'COVERS_COMMISSION_RATE',
  COVERS_COMMISSION_SERVICE = 'COVERS_COMMISSION_SERVICE',
  COVERS_DEPENDENTS = 'COVERS_DEPENDENTS',
  COVERS_DOCUMENTATION = 'COVERS_DOCUMENTATION',
  COVERS_EXCESS_DEDUCT = 'COVERS_EXCESS_DEDUCT',
  COVERS_LAPSE = 'COVERS_LAPSE',
  COVERS_LOAN = 'COVERS_LOAN',
  COVERS_PAID_UP = 'COVERS_PAID_UP',
  COVERS_PERIL = 'COVERS_PERIL',
  COVERS_SURRENDER = 'COVERS_SURRENDER',
  COVERS_TAXES_LEVIES = 'COVERS_TAXES_LEVIES',
  COVERS_TERMS = 'COVERS_TERMS',
  COVERS_TERMS_OPT = 'COVERS_TERMS_OPT',
  COVERS_TREATY = 'COVERS_TREATY',
  COVERS_UW = 'COVERS_UW',
  COVERS_UW_QUOTE = 'COVERS_UW_QUOTE',
  COVERS_WAIVED = 'COVERS_WAIVED',
  COVER_ALL = 'COVER_ALL',
  COVER_BENEFITS = 'COVER_BENEFITS',
  COVER_BEN_TERM_OPT = 'COVER_BEN_TERM_OPT',
  COVER_ENDORSEMENT = 'COVER_ENDORSEMENT',
  COVER_PREM_TERM_OPT = 'COVER_PREM_TERM_OPT',
  CREATE_COVERS_PREMIUM = 'CREATE_COVERS_PREMIUM',
  DISCOUNT_RATE = 'DISCOUNT_RATE',
  DISCOUNT_RATE_VALUE = 'DISCOUNT_RATE_VALUE',
  DOCUMENT = 'DOCUMENT',
  ENDORSE_TERM = 'ENDORSE_TERM',
  FIELD_CORRESPONDENCE = 'FIELD_CORRESPONDENCE',
  FILTER_CORRESPONDENCE = 'FILTER_CORRESPONDENCE',
  GENERAL_RATE = 'GENERAL_RATE',
  GENERAL_RATE_VALUES = 'GENERAL_RATE_VALUES',
  GEN_ACC_TRANS = 'GEN_ACC_TRANS',
  INDIVIDUAL_CLIENT = 'INDIVIDUAL_CLIENT',
  INTEREST_RATE = 'INTEREST_RATE',
  INTEREST_RATE_VALUE = 'INTEREST_RATE_VALUE',
  LINK_CORRESPONDENCE = 'LINK_CORRESPONDENCE',
  LOAN_RATE = 'LOAN_RATE',
  ORGANISATION_COMPANY = 'ORGANISATION_COMPANY',
  ORGANISATION_COMPANY_EMAIL_GATEWAY = 'ORGANISATION_COMPANY_EMAIL_GATEWAY',
  ORGANISATION_COMPANY_PAYOUT = 'ORGANISATION_COMPANY_PAYOUT',
  ORGANISATION_COMPANY_PAYOUT_METHOD = 'ORGANISATION_COMPANY_PAYOUT_METHOD',
  ORGANISATION_COMPANY_TIER = 'ORGANISATION_COMPANY_TIER',
  ORGANISATION_COMPANY_TOLERANCE = 'ORGANISATION_COMPANY_TOLERANCE',
  PAYOUT_PAYEE = 'PAYOUT_PAYEE',
  POLICY = 'POLICY',
  POLICY_BANK = 'POLICY_BANK',
  POLICY_COMMISSION = 'POLICY_COMMISSION',
  POLICY_COVERS = 'POLICY_COVERS',
  POLICY_COVERS_ANNUITY = 'POLICY_COVERS_ANNUITY',
  POLICY_COVERS_PERIL = 'POLICY_COVERS_PERIL',
  POLICY_COVER_ANNUITY_FREQ = 'POLICY_COVER_ANNUITY_FREQ',
  POLICY_COVER_ANNUITY_FREQUENCY = 'POLICY_COVER_ANNUITY_FREQUENCY',
  POLICY_COVER_ANNUITY_PAYEE = 'POLICY_COVER_ANNUITY_PAYEE',
  POLICY_COVER_ANNUITY_SUSPEND = 'POLICY_COVER_ANNUITY_SUSPEND',
  POLICY_PRICING = 'POLICY_PRICING',
  POLICY_REINSURANCE = 'POLICY_REINSURANCE',
  POLICY_RELATED_CLIENTS = 'POLICY_RELATED_CLIENTS',
  POLICY_REL_MANAGER = 'POLICY_REL_MANAGER',
  POLICY_RENEWAL_SETUP = 'POLICY_RENEWAL_SETUP',
  POLICY_UWR = 'POLICY_UWR',
  POLICY_UWR_DECISION = 'POLICY_UWR_DECISION',
  POLICY_UWR_EXAMS = 'POLICY_UWR_EXAMS',
  PRODUCT = 'PRODUCT',
  PRODUCT_ALL = 'PRODUCT_ALL',
  PRODUCT_COVERS_BASIC = 'PRODUCT_COVERS_BASIC',
  PRODUCT_DEFAULTS = 'PRODUCT_DEFAULTS',
  PRODUCT_DEPENDENT_COVERS = 'PRODUCT_DEPENDENT_COVERS',
  PRODUCT_DOCUMENTATION = 'PRODUCT_DOCUMENTATION',
  PRODUCT_POLICY_MAND_INFO = 'PRODUCT_POLICY_MAND_INFO',
  PRODUCT_QUOTE_MAND_INFO = 'PRODUCT_QUOTE_MAND_INFO',
  PROVIDER_PAYMENT = 'PROVIDER_PAYMENT',
  RATE_CLONE = 'RATE_CLONE',
  SERVICING_AGENT = 'SERVICING_AGENT',
  SHORT_TERM_RATE = 'SHORT_TERM_RATE',
  STATUS_CODE = 'STATUS_CODE',
  SUM_ASSURED = 'SUM_ASSURED',
  SUSPENSE_SWITCH_AUTH = 'SUSPENSE_SWITCH_AUTH',
  TARIFF = 'TARIFF',
  TASK_SETUP = 'TASK_SETUP',
  TAX_RATE = 'TAX_RATE',
  TEMPLATE_CORRESPONDENCE = 'TEMPLATE_CORRESPONDENCE',
  TREATY = 'TREATY',
  USER_GROUP = 'USER_GROUP',
  USER_MENU = 'USER_MENU',
  USER_UPDATE = 'USER_UPDATE',
  USER_USER = 'USER_USER',
  UW_LOADING = 'UW_LOADING',
  UW_QUESTION = 'UW_QUESTION',
  WORKFLOW_ERROR = 'WORKFLOW_ERROR',
  WORKFLOW_SNOOZE = 'WORKFLOW_SNOOZE',
}

export const endorsementCatSubCat: {
  [x in EEndorseCat]?: { [sc in EEndorseSubCat]?: {} };
} = {
  [EEndorseCat.POLICY]: {
    [EEndorseSubCat.POLICY_BANK]: {},
    [EEndorseSubCat.POLICY_COVER_ANNUITY_PAYEE]: {},
    [EEndorseSubCat.POLICY_COVER_ANNUITY_SUSPEND]: {},
    [EEndorseSubCat.POLICY_COVERS_ANNUITY]: {},
    [EEndorseSubCat.POLICY_RELATED_CLIENTS]: {},
  },
  [EEndorseCat.PRODUCT]: {
    [EEndorseSubCat.AUTO_SETTLE_COVERS]: {},
    [EEndorseSubCat.AUTO_TERMINATE]: {},
    [EEndorseSubCat.BENEFITS_ESCAL]: {},
    [EEndorseSubCat.COVER_BENEFITS]: {},
    [EEndorseSubCat.COVERS_BENEFIT_SCHEDULE]: {},
    [EEndorseSubCat.COVERS_DEPENDENTS]: {},
    [EEndorseSubCat.COVERS_WAIVED]: {},
    [EEndorseSubCat.PRODUCT]: {},
    [EEndorseSubCat.PRODUCT_COVERS_BASIC]: {},
    [EEndorseSubCat.PRODUCT_DEFAULTS]: {},
    [EEndorseSubCat.PRODUCT_DEPENDENT_COVERS]: {},
    [EEndorseSubCat.PRODUCT_POLICY_MAND_INFO]: {},
    [EEndorseSubCat.SUM_ASSURED]: {},
  },
  [EEndorseCat.RATE]: {
    [EEndorseSubCat.BONUS_RATE]: {},
    [EEndorseSubCat.DISCOUNT_RATE]: {},
    [EEndorseSubCat.GENERAL_RATE]: {},
    [EEndorseSubCat.GENERAL_RATE_VALUES]: {},
    [EEndorseSubCat.INTEREST_RATE]: {},
    [EEndorseSubCat.LOAN_RATE]: {},
    [EEndorseSubCat.SHORT_TERM_RATE]: {},
    [EEndorseSubCat.TAX_RATE]: {},
  },
};
export function getEndorsementSubCats() {
  return Object.keys(EEndorseSubCat).map((key) => ({
    value: EEndorseSubCat[key],
    label: fieldToLabelMap[key.toLowerCase()] || EEndorseSubCat[key]?.split('_').join(' '),
  }));
}

export function getEndorsementType() {
  return Object.keys(EEndorsementType).map((key) => EEndorsementType[key]);
}

export type IEndorsementQuery = Partial<
  IGetQuery<{
    auth?: EEndorseStatus;
    authBy?: string;
    authOnEnd?: string;
    authOnStart?: string;
    createdBy?: string;
    createdOnEnd?: string;
    createdOnStart?: string;
    endorseCat?: EEndorseCat;
    endorsementNo?: string;
    endorseType?: EEndorsementType;
    pageNumber?: number;
    pageSize?: number;
    refNo?: string;
    refCat?: string;
    subCategory?: EEndorseSubCat;
    updatedBy?: string;
    updatedOnEnd?: string;
    updatedOnStart?: string;
    _withCache?: boolean;
  }>
>;

export interface IEndorsementSearchContent {
  id: string;
  endorsementNo: string;
  type: EEndorsementType;
  endorseCat: EEndorseCat;
  subCategory: EEndorseSubCat;
  authOn?: any;
  authBy?: any;
  auth?: EEndorseStatus;
  remark?: any;
  newData?: any;
  oldData?: any;
  rawOldData?: any;
  createdOn: string;
  updatedOn?: any;
  createdBy: string;
  updatedBy?: any;
  refCat: string;
  refNo: string;
}

export enum EEndorsementType {
  delete = 'DELETE',
  edit = 'EDIT',
  new = 'NEW',
}

export interface IEndorsementTextCase extends ITextCase2 {
  children?: IEndorsementTextCase[][];
  type?: InputType;
}

export interface IKeyToLabel {
  [key: string]: string;
}

export interface IEndorsementConfig {
  /** This keeps the excluded fields for the root fields */
  excludedFields?: { [x: string]: 1 | 0 };
  /** This keeps the excluded fields for the inner objects */
  innerExcludedFields?: { [x: string]: { [x: string]: 1 | 0 } };
  /**
   * Attach a custome label to a field
   */
  keyToValueFormatterMap?: { [x: string]: IEndorsementTextCase['formatter'] };
  /**
   * Attach a custome label to a field
   */
  keyToLabelMap?: IKeyToLabel;
  /**
   * Don't use the default excluded fields
   */
  noUseDefaultExcludedFields?: boolean;
  subCat: EEndorseSubCat;
  status?: EEndorseStatus;
  isNewData?: boolean;
  columns?: ITableCol[];
}

export interface IEndorsementHandlerParam<T> {
  /**
   * This is the submission request's response from the server
   */
  endorsementRes: EndorsementResponse<T>;
  /**
   * The prefix for the success message to be generated where endorsement is required (excluding the preceding space character)
   */
  endorsementMsgPrefix?: string;
  /**
   * The success message to be shown where endorsement is not required (it will not include the prefix)
   */
  fullMsg?: string;
  /**
   * The prefix for the success message to be generated where endorsement is not required (excluding the preceding space character)
   */
  msgPrefix?: string;
  /**
   * The current route
   */
  route?: ActivatedRoute;
  /**
   * The callback function to be called after the message has been generated if endorsement was not required
   *  @param response This is the endorsement submission request's response from the server
   */
  cb?: (res: T) => any;
  /**
   * The callback function to be called after the message has been generated if endorsement was required and no current route was provided
   *  @param response This is the endorsement submission request's response from the server
   */
  endorsementCB?: (res: EndorsementResponse<T>) => any;
  /**
   * Navigation Extras to be added when using the current route to route to a parent route where endorsement was required
   */
  extraRouteParams?: NavigationExtras;
  noEndorsmentFeedbackButtons?: (res: T) => IInfoDialogBtn[];
  endorsmentFeedbackButtons?: (res: T) => IInfoDialogBtn[];
}

export namespace IEndorsementSubCatModels {
  export interface IPolicyAnnuityPayee {
    statusCode: number;
    message: string;
    response: {
      id: number;
      payeeNo: string;
      proportion: number;
      paymentMethod: string;
      category: string;
      bankNo: string;
      policyCoversAnnuityId: number;
      policyCoversAnnuityResponse?: IPolicyCoversAnnuityResponse;
    };
  }

  interface IPolicyCoversAnnuityResponse {
    id: number;
    quoteNo: string;
    coverCode: string;
    annuityType: string;
    bonusPaymentAmt?: any;
    bonusPaymentBasis?: any;
    activationEvent?: any;
    escalIndex?: any;
    escalBasis: string;
    escalRate: number;
    fundedPol?: any;
    annuityAmt: number;
    guarPeriod: number;
    payFreq: string;
    pfaNo: string;
    policyNo: string;
    revRate: number;
    sourceOfPremium: string;
    annuityTermMo?: any;
    custodianNo?: any;
    policyCode: string;
    policyNoSuffix?: any;
    rev: boolean;
    deferredPeriodMo?: any;
    policyId: number;
    annuityPaySchedule: boolean;
    policyCoversId: number;
    payeeRelOwner?: any;
    active: boolean;
    alpha: string;
    claimNo?: any;
    currency: string;
    issuedOn?: any;
    companyCode: string;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    osGuarPeriod?: any;
    annAnnuityAmt?: any;
  }

  export interface IPolicyCoverAnnuitySuspend {
    id?: any;
    alpha?: any;
    suspendedBy: string;
    suspendedOn: string;
    suspendedOf?: any;
    policyId?: any;
    suspend: boolean;
    policyCoverAnnuityId: number;
    policyNo: string;
    policyCode: string;
  }
  export interface IPolicyRelatedClients {
    busLine?: any;
    codeTitle?: any;
    enroleeNo?: any;
    enroleeNoSuffix?: any;
    groupNo?: any;
    id?: any;
    policyCode: string;
    policyGroupId?: any;
    policyId?: any;
    policyNo: string;
    policyNoSuffix?: any;
    proportion: number;
    quoteNo?: any;
    relClient: string;
    relClientName?: any;
    relDate: string;
    type: string;
  }
}
