import { environment as SDKEn } from 'ets-fe-ng-sdk';
import { Config } from '../lib/configs/index.config';
import { Environment } from '../lib/Shared/models/environment.model';
import { Config as JupiterConfig } from 'projects/evolutics-admin-ui/src/app/configs/index.config';

export const environment: Environment = new Environment(true, 'Staging');
environment.apiBaseUrl = Config.APIStagingServer;
environment.jupiterAPIURL = JupiterConfig.APIStagingServer;
environment.debug = true;
environment.authenticate = true;
environment.email="contact@evoluticstech.com";
environment.phoneNumber="08123456789";
environment.useAuthCookies = true;
SDKEn.reinit(environment);