import { CurrencyPipe, DatePipe, DecimalPipe, Location, TitleCasePipe } from '@angular/common';
import { ElementRef, Injectable, QueryList, Type, effect, inject } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { InputService as IS1 } from './input.service';
import { LoaderService } from './page-loader.service';
import { PageService } from './page.service';
import { Observable, Subscription, concat, lastValueFrom, map, merge, of } from 'rxjs';
import {
  EndorsementFuncs,
  EndorsementResponse,
  IEndorsementHandlerParam,
} from '@Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import {
  EPageType,
  ETSConfirmDialogService,
  InfoDialogService,
  UtilityService as US,
  EVFunctions,
  ICodeDescription,
  ICodeTitle,
  TableService,
} from 'ets-fe-ng-sdk';
import { environment } from '@environments/environment';
import { Config } from '@configs/index.config';
import { Environment } from '@Shared/models/environment.model';
import { PageTemplateComponent } from '@Shared/components/page-template/page-template.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Papa, { ParseLocalConfig } from 'papaparse';
import { IFormData, IFormDataIndex, IModalConfig } from '@Shared/models/index.model';
import { NotificationsService } from '@Shared/components/notifications/notifications.service';
import { cloneDeep, uniqBy } from 'lodash-es';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormArray, FormControl } from '@angular/forms';
import { EReportFormat } from '@Reusables/reusable-pages/Report/report-extras/report.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'; 
import { DebouncerService } from './debouncer.service';
import { PageToComponentService } from '@Shared/components/page-to-component/page-to-component.service';
import { PageChildren } from 'projects/evolutics-client-ui/src/app/configs/menu-configs/children.config';
declare const $: any;

@Injectable({ providedIn: 'root' })
export class UtilityService extends US<Environment> {
  config = Config;
  environment: Environment = environment;
  $ = window['$'];
  pageSizeOptions = [5, 10, 15, 20, 50, 100, 200, 500, 1000];
  datePipeDate = 'EE, MMMM d, y';
  datePipeDateLean = 'EE, MMM d, y';
  datePipeDateTimeLean = 'EE, MMM d, y hh:mm a';
  readonly customIDField = '__id';
  PC = PageChildren;
  public dialog = inject(MatDialog);
  public snackBar = inject(MatSnackBar);
  public router = inject(Router);
  public titleS = inject(Title);
  public location = inject(Location);
  public inputService = inject(IS1);
  public pS2 = inject(LoaderService);
  public currencyPipe = inject(CurrencyPipe);
  public numberPipe = inject(DecimalPipe);
  public titleCasePipe = inject(TitleCasePipe);
  public pageS = inject(PageService);
  public confirmDialogService = inject(ETSConfirmDialogService);
  public infoDialogService = inject(InfoDialogService);
  public pageToComponentService = inject(PageToComponentService);
  public nS = inject(NotificationsService);
  public datePipe = inject(DatePipe);
  public debouncerService = inject(DebouncerService);
  public responsive = inject(BreakpointObserver);
  public tableService = inject(TableService);
  constructor() {
    super();
    this.tableService.arrayToCSVWorker = this.arrayToCSVWorker;
    effect(() => {
      const appIsLoading = this.pS2.isLoading();
      if (appIsLoading) document.getElementsByTagName('body')?.[0]?.classList?.add('appIsLoading');
      else document.getElementsByTagName('body')?.[0]?.classList?.remove('appIsLoading');
    });
    // this.toast = this.toastNotificationsService.toastShortcut()
  }

  onlyOneInput(inputs: FormControl[]) {
    const nInputs = inputs?.map((inp) => ({ id: this.genRandomID, inp })) || [];
    return merge(...nInputs.map((i) => i.inp.valueChanges.pipe(map((val) => ({ id: i.id, val }))))).subscribe(
      (r) => {
        // debugger;
        if (r.val) nInputs.filter((x) => x.id != r.id).forEach((x) => x.inp.disable({ emitEvent: false }));
        else nInputs.filter((x) => x.id != r.id).forEach((x) => x.inp.enable({ emitEvent: false }));
      },
    );
  }

  startLoader = () => this.pS2.play();
  stopLoader = () => this.pS2.stop();
  hasNoValue = (val: string | number) => val?.toString() == null || val?.toString() == '';
  typeCaster = <T>(value: T) => value;

  handleDeleteWithEndorsement = async <T>(
    index: number,
    arr: any[],
    deleteService: (...args) => Promise<T> | Observable<T>,
    addRowFunc: (...args) => any,
  ) => {
    this.handleRowDelete(index, arr, deleteService, addRowFunc, (r) => {
      const res = new EndorsementResponse(r);
      if (res.usedEndorsement) this.info(`Deletion request ${res.endorsementNo} is pending authorization`, 2);
    });
  };

  /**
   *Handles the success message generation and behaviour if a submission request might involve endorsement
   * @param config This is the configuration for the endorsement's successful submission
   */
  handleEndorsementSubmmission = async <T>(config: IEndorsementHandlerParam<T>) => {
    // debugger;
    if (config.endorsementRes.usedEndorsement == null)
      config.endorsementRes = new EndorsementResponse(config.endorsementRes);
    if (config.endorsementRes.usedEndorsement) {
      await this.info(
        EndorsementFuncs.successMessage(
          config.endorsementRes,
          config.endorsementMsgPrefix,
          'This form will be closed',
        ),
        2,
        undefined,
        config.endorsmentFeedbackButtons?.(config.endorsementRes.data),
      );
      if (config.route)
        this.router.navigate(['../'], {
          relativeTo: config.route,
          ...config.extraRouteParams,
        });
      else if (config.endorsementCB) config.endorsementCB(config.endorsementRes);
    } else {
      await this.info(
        config?.fullMsg
          ? config.fullMsg
          : EndorsementFuncs.successMessage(config.endorsementRes, config.msgPrefix),
        1,
        undefined,
        config.noEndorsmentFeedbackButtons?.(config.endorsementRes.data),
      );
      if (config.cb) config.cb(config.endorsementRes.data);
    }
  };
  /**
   *Handles the success message generation and behaviour if a deletion request might involve endorsement
   * @param config This is the configuration for the endorsement's successful deletion request
   */
  handleEndorsementDeletion = <T>(config: IEndorsementHandlerParam<T>) => {
    if (config.endorsementRes.usedEndorsement == null)
      config.endorsementRes = new EndorsementResponse(config.endorsementRes);
    if (config.endorsementRes.usedEndorsement) {
      this.info(
        EndorsementFuncs.successDeletionMessage(config.endorsementRes, config.endorsementMsgPrefix),
        2,
      );
      if (config.route) this.router.navigate(['../'], { relativeTo: config.route });
      else if (config.endorsementCB) config.endorsementCB(config.endorsementRes);
      return;
    } else {
      this.info(EndorsementFuncs.successDeletionMessage(config.endorsementRes, config.msgPrefix), 2);
      config.cb ? config.cb(config.endorsementRes.data) : null;
    }
  };
  /**
   * Uses a web worker to perform a task
   * @example new Worker(new URL('./upload-csv.worker', import.meta.url))
   * @param worker Web worker object
   * @param inputData Data being passed into web worker
   * @returns Data gotten from web worker
   */
  useWebWorker<T = any>(
    worker: Worker,
    inputData: any,
    conditions?: { stop: (res) => boolean; error: (res) => boolean },
  ) {
    return new Observable<T>((sub) => {
      try {
        if (typeof Worker !== 'undefined') {
          worker.postMessage(inputData);
          worker.onmessage = (r) => {
            // debugger
            // console.log(e);
            const data = r.data;
            if (conditions) {
              if (conditions.error(data)) {
                sub.error(data);
                sub.complete();
                worker.terminate();
              } else if (conditions.stop(data)) {
                sub.next(data);
                sub.complete();
                worker.terminate();
              } else {
                sub.next(data);
              }
            } else {
              sub.next(data as any);
              sub.complete();
              worker.terminate();
            }
          };
        } else {
          throw 'noWorker';
        }
      } catch (e) {
        console.log(e);
        sub.error(e);
        sub.complete();
      }
    });
  }

  fullDateTime = (timestamp: string | number): string => {
    return this.datePipe.transform(timestamp);
  };

  formatDateTime = (dateTime: string): string => (dateTime ? `${dateTime}Z`.replace('ZZ', 'Z') : dateTime);
  pageToModalOpener = this.pageToComponentService.pageToModalOpener;

  pageToModalOpener2 = <ModalData>(
    component: Type<PageTemplateComponent<ModalData>>,
    title: string,
    modelData?: ModalData,
    pageType?: EPageType,
  ): import('rxjs').Observable<any> => {
    return of();
  };

  /**
   * Strip item for cloning. It removes the identifier fields like id,
   * @param item Item to strip
   * @param extraFieldsToStrip Custom fields to check for. Example: clientNo, coverCode, coverID
   */
  stripForCloning = <T = { [x: string]: any }>(item: T, extraFieldsToStrip?: string[]) => {
    const fieldsToDelete = ['id'].concat(extraFieldsToStrip || []);
    // debugger;
    if (Array.isArray(item)) {
      for (let index = 0; index < item.length; index++) {
        this.stripForCloning(item[index], extraFieldsToStrip);
      }
      return item;
      return Object.keys(item)?.length > 0 ? item : undefined;
    } else if (typeof item == 'object' && item != null) {
      fieldsToDelete.forEach((f) => delete item[f]);
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          const element = item[key];
          if (typeof element == 'object' && element != null)
            this.stripForCloning(element, extraFieldsToStrip);
          else if (Array.isArray(element)) this.stripForCloning(element, extraFieldsToStrip);
          // else if (element != null) {
          //   fieldsToDelete.forEach((f) => delete element[f]);
          // }
        }
      }
      return item;
      return Object.keys(item)?.length > 0 ? item : undefined;
    } else {
      return item;
    }
  };

  /**
   * Strip item for cloning. It removes the identifier fields like id,
   * @param item Item to strip
   * @param modelItem Custom fields to check for. Example: clientNo, coverCode, coverID
   */
  regenObject = <T = { [x: string]: any }>(item: T, modelItem: T) => {
    // debugger;
    if (!item) return modelItem;
    for (const key in modelItem) {
      if (Object.prototype.hasOwnProperty.call(modelItem, key)) {
        const modelElement = modelItem[key];
        const itemElement = item[key];
        if (Array.isArray(itemElement) && itemElement.length == 0) item[key] = modelElement;
        else if (itemElement == null) item[key] = modelElement;
        else item[key] = this.regenObject(item[key], modelItem[key]);
      }
    }
    return Object.keys(item || {}).length > 0 ? item : undefined;
  };

  removeEmptyObjects = <T>(obj: T): T => {
    if (obj == null || obj == undefined) return undefined;
    if (Array.isArray(obj)) {
      for (let index = 0; index < obj.length; index++) {
        obj[index] = this.removeEmptyObjects(obj[index]);
      }
      return obj.filter((x) => x != null && x != undefined) as T;
    } else if (typeof obj == 'object') {
      if (Object.keys(obj).length > 0) {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj[key] = this.removeEmptyObjects(obj[key]);
          }
        }
        return obj;
      } else return undefined;
    } else return obj;
  };
  flattenObj = (ob) => {
    if (!ob) return null;
    if (!Object.keys(ob)?.length) return null;
    // debugger;
    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
        const temp = this.flattenObj(ob[i]);
        if (!temp) continue;
        for (const j in temp) {
          // Store temp in result
          result[j] = temp[j];
        }
      }

      // Else store ob[i] in result directly
      else {
        result[i] = ob[i];
      }
    }
    console.log(result);
    return result;
  };
  findMatchingValue(set, properties) {
    return set.filter(function (entry) {
      return Object.keys(properties).every(function (key) {
        return entry[key] === properties[key];
      });
    });
  }
  /**
   * Download file from link
   * @param url Link to download file
   * @param filename Name of file including extension
   */
  async downloadFromLink(url: string, filename: string) {
    console.log('download link', url);
    // debugger
    let downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  exportHTMLToPDF(
    content: HTMLElement,
    fileName: string,
    config?: { width: number; widthMultiplier?: number; height?: number },
  ) {
    return html2canvas(content).then(async (canvas) => {
      let fileWidth = config?.width || canvas.width * (config?.widthMultiplier || 1);
      let fileHeight = config?.height || (canvas.height * fileWidth) / canvas.width;
      // debugger
      console.log([fileWidth, fileHeight]);
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF(
        canvas.height > canvas.width ? 'p' : 'l',
        'px',
        [fileWidth, fileHeight],
        true,
        // false
      );
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      return await PDF.save(fileName + '.pdf', {
        returnPromise: true,
      });
    });
  }

  exportHTMLsToPDF(
    contents: QueryList<ElementRef<HTMLDivElement>>,
    fileName: string,
    config?: {
      width: number;
      widthMultiplier?: number;
      height?: number;
      returnFile: true;
      selector?: string;
    },
  ): Promise<File>;
  exportHTMLsToPDF(
    contents: QueryList<ElementRef<HTMLDivElement>>,
    fileName: string,
    config?: {
      width: number;
      widthMultiplier?: number;
      height?: number;
      selector?: string;
    },
  ): Promise<void>;
  async exportHTMLsToPDF(
    contents: QueryList<ElementRef<HTMLDivElement>>,
    fileName: string,
    config?: {
      width: number;
      widthMultiplier?: number;
      height?: number;
      returnFile?: true;
      selector?: string;
    },
  ) {
    if (!contents && !config?.selector) return;
    const _contents = config?.selector
      ? Array.from(document.querySelectorAll<HTMLDivElement>(config?.selector))
      : contents.map((x) => x.nativeElement);

    if (!_contents?.length) return;

    const first = _contents[0];
    console.log(config);
    let PDF = new jsPDF(
      first.offsetHeight > first.offsetWidth ? 'p' : 'l',
      'px',
      [config?.width, config?.height || (first.offsetHeight * config?.width) / first.offsetWidth],
      true,
    );
    // debugger;
    await lastValueFrom(
      concat(
        ..._contents.map((content, i) => {
          return of(
            html2canvas(content).then(async (canvas) => {
              let fileWidth = config?.width;
              let fileHeight = config?.height || (canvas.height * fileWidth) / canvas.width;
              console.log(canvas.width, canvas.height, [fileWidth, fileHeight]);
              const FILEURI = canvas.toDataURL('image/png', 1);
              PDF.addImage(FILEURI, 'PNG', 0, 0, fileWidth, fileHeight);
              console.log(content);
              PDF.addPage();
            }),
          );
        }),
      ),
    );
    PDF.deletePage(PDF.getNumberOfPages());
    return config?.returnFile
      ? new File([PDF.output('blob')], fileName + '.pdf', {
          type: 'application/pdf',
        })
      : await PDF.save(fileName + '.pdf', {
          returnPromise: true,
        });
  }

  absoluteRouting = (path: string, navigationExtras?: NavigationExtras) => {
    return this.go(`/${location.pathname.split('/')[1]}/${path}`, navigationExtras);
  };

  HTMLToPDFBlob(
    content: HTMLElement,
    fileName: string,
    config?: { width: number; widthMultiplier?: number; height?: number },
  ) {
    return html2canvas(content).then(async (canvas) => {
      let fileWidth = config?.width || canvas.width * (config?.widthMultiplier || 1);
      let fileHeight = config?.height || (canvas.height * fileWidth) / canvas.width;
      // debugger
      console.log([fileWidth, fileHeight]);
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF(
        canvas.height > canvas.width ? 'p' : 'l',
        'px',
        [fileWidth, fileHeight],
        true,
        // false
      );
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      return new File([PDF.output('blob')], fileName + '.pdf', {
        type: 'application/pdf',
      });
      // return PDF.output('bloburl');
      // return new Blob([PDF.output("bloburl")], { type: 'application/pdf' });
    });
  }
  refresh() {
    location.href = location.href;
  }
  /**
   * Deletes the specified fields from an object
   * @param obj
   * @param fields Fields to delete
   * @returns
   */
  deleteFields = <T>(obj: T, fields: (keyof T)[]) => {
    const ret: T = {} as any;
    const fieldsMap = this.arrayToMap(
      fields.map((x) => ({ field: x })),
      'field',
    );
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (!fieldsMap[key]) ret[key] = obj[key];
      }
    }
    return ret;
  };

  async csvToArray<T = any>(file: File, extraConfig?: Partial<ParseLocalConfig>) {
    return new Promise<T[]>((res, rej) => {
      let csv = Papa.parse<T>(file, {
        header: true,
        complete: (r) => {
          res(r.data);
        },
        worker: true,
        skipEmptyLines: extraConfig?.skipEmptyLines,
        error: (e) => {
          rej(e);
        },
      });
    });
  }

  arrayToCSVWorker = async (array: any[], filename: string, columns?: string[], showHeader = true) => {
    debugger;

    this.useWebWorker(new Worker(new URL(`./json-downloader.worker`, import.meta.url)), {
      array,
      columns,
      showHeader,
      format: EReportFormat.csv,
    }).subscribe((csv) => {
      // debugger;
      this.downloader(csv, filename?.endsWith('.csv') ? filename : filename + '.csv');

      this.info(`Downloaded ${filename}`);
    });
  };

  /**
   * Converts timestamp into hr,min,sec
   * @param timeTaken Time taken in timestamp
   * @returns
   */
  formatTimeTaken = (timeTaken: number) => {
    if (timeTaken == null) return '';
    const timeLeft = this.secondsToHourMinSec(Math.round(timeTaken / 1000) || 1);
    return (
      (timeLeft.hours ? timeLeft.hours + 'hr' : '') +
      (timeLeft.mins ? timeLeft.mins + 'min' : '') +
      (timeLeft.secs ? timeLeft.secs + 'sec' : '')
    );
  };
  /**
   * Converts an array to a key value pair.
   * @param arr Array to be converted
   * @param keyField The field to be used as the key
   * @returns An index object containing the keyField as the index key and each item of the array as value assigned to each index
   */
  arrayToMap<T>(arr: T[], keyField: keyof T): { [x: string]: T };
  arrayToMap<T, NT>(arr: T[], keyField: keyof T, map: (item: T) => NT): { [x: string]: NT };
  arrayToMap<T, NT>(arr: T[], keyField: keyof T, map?: (item: T) => NT) {
    const ret: { [x: string]: T | NT } = {};
    for (const iterator of arr) {
      ret[iterator[keyField?.toString()]] = map ? map(iterator) : iterator;
    }
    return ret;
  }
  /**
   * Gets all the fields in all the items in an array
   * @param arr
   * @returns The index map of the fields with the fields serving as the index keys. Each entry contains the count of the times that a value was found for the field
   */
  getAllFieldsInArray = <T>(arr: T[]) => {
    const ret: { [field in keyof T]?: { field: keyof T; count: number } } = {};
    for (const item of arr) {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          const element = item[key];
          const existingItem = ret[key];
          if (existingItem && element != null) existingItem.count++;
          ret[key] = { field: key, count: element != null ? 1 : 0 };
        }
      }
    }
    return ret;
  };
  sortObjectFields = <T>(obj: T) => {
    if (obj == null || typeof obj != 'object') return obj;
    const nObj: T = {} as any;
    const fieldNames = Object.keys(obj);
    fieldNames.sort();
    // console.log(fieldNames);
    for (const key of fieldNames) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let element = obj[key];
        if (Array.isArray(element)) {
          nObj[key] = cloneDeep(element);
          for (let index = 0; index < element.length; index++) {
            nObj[key][index] = this.sortObjectFields(element[index]);
          }
        } else if (typeof element == 'object' && element != null) {
          nObj[key] = this.sortObjectFields(element);
        } else nObj[key] = element;
      }
    }
    return nObj;
  };

  makeArrayItemsUnique = <T>(arr: T[], field: keyof T): T[] => {
    return uniqBy(arr, field);
  };
  openHTMLInFullscreen<TMetadata>(
    elem: HTMLElement,
    metadata?: TMetadata,
    onLeave?: (metadata: TMetadata) => any,
  ) {
    if (elem.requestFullscreen) {
      elem.onfullscreenchange = (e) => {
        if (onLeave)
          elem.onfullscreenchange = (e) => {
            //  debugger;
            onLeave(metadata);
          };
      };
      elem.requestFullscreen();
    } else if (elem['webkitRequestFullscreen']) {
      /* Safari */
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) {
      /* IE11 */
      elem['msRequestFullscreen']();
    }
  }
  closeFullscreen() {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['webkitExitFullscreen']) {
        /* Safari */
        document['webkitExitFullscreen']();
      } else if (document['msExitFullscreen']) {
        /* IE11 */
        document['msExitFullscreen']();
      }
    } catch (error) {}
  }
  funcToWorker = <TInput, TResponse>(fn: (e: TInput) => TResponse, inputData: TInput) => {
    // debugger;
    const wrapper = `addEventListener('message', ({ data }) => {
      console.log('worker data', data);
      const func = ${fn.toString()};
      const response = func(data);
      postMessage(response);
    })`;
    const blob = new Blob([`(${wrapper})`], {
      type: 'text/javascript',
    });
    const url = URL.createObjectURL(blob);
    return this.useWebWorker<TResponse>(new Worker(url), inputData);
  };

  setFormData = (field: string, data: IFormData, formDataIndex: IFormDataIndex) => {
    formDataIndex[field] = data;
  };
  fieldToLabelMap = this.formatField;

  formatCount = (num: number) => {
    if (!num) {
      return 0 + '';
    } else {
      // hundreds
      if (num <= 999) {
        return num + '';
      }
      // thousands
      else if (num >= 1000 && num <= 9999) {
        return this.numberPipe.transform(num / 1000, '1.0-3') + 'K';
      } else if (num >= 10000 && num <= 999999) {
        return this.numberPipe.transform(num / 1000, '1.0-1') + 'K';
      }
      // millions
      else if (num >= 1000000 && num <= 9999999) {
        return this.numberPipe.transform(num / 1000000, '1.0-3') + 'M';
      } else if (num >= 10000000 && num <= 999999999) {
        return this.numberPipe.transform(num / 1000000, '1.0-1') + 'M';
      }
      // billions
      else if (num >= 1000000000 && num <= 999999999999) {
        return this.numberPipe.transform(num / 1000000000, '1.0-3') + 'B';
      }
      // trillions
      else {
        return this.numberPipe.transform(num / 1000000000000, '1.0-3') + 'T';
      }
    }
  };

  responsiveValues<T>(config: { default?: T; small?: T; medium?: T; large?: T; xlarge?: T }): T {
    // debugger;
    const _config = {
      XSmall: config.default,
      Small: config.small,
      Medium: config.medium,
      Large: config.large,
      XLarge: config.xlarge,
    };
    // debugger;
    for (const key in _config) {
      if (Object.prototype.hasOwnProperty.call(_config, key)) {
        if (this.responsive.isMatched(Breakpoints[key])) return _config[key];
      }
    }
    return Object.values(_config)[0];
  }

  /**
   * Returns the value of the current --primary variable
   */
  getPrimaryColor = () => {
    const d = document.createElement('i');
    d.style.color = 'var(--primary)';
    document.body.appendChild(d);
    return window.getComputedStyle(d, null).getPropertyValue('color');
  };
  /**
   * Returns the value of the current --primary variable with some transparency
   * @param percentage
   * @example 0 - 1
   */
  getPrimaryColorTrans = (percentage: number) => {
    const col = this.getPrimaryColor();
    return col.endsWith(')')
      ? col
          .split(', ')
          .join(' ')
          .replace(')', ' / ' + percentage * 100 + '% )')
      : col;
  };

  rearrangeArray = <TItem>(event: CdkDragDrop<TItem[]>, array: TItem[]) => {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
  };

  noNumberValidator(control: FormControl<string>) {
    return control.value && /\d/.test(control.value) ? { custom: `Numbers are not allowed` } : null;
  }

  onlyNumberValidator(control: FormControl<string>) {
    return !control.value || /^\d+$/.test(control.value) ? null : { custom: `Only numbers are allowed` };
  }

  uniqueBy = <T>(arr: T[], uniqueFields: (keyof T)[]) => {
    if (!arr?.length) return arr;
    const map: { [x: string]: boolean } = {};
    const newArr: T[] = [];
    for (const item of arr) {
      const alpha = uniqueFields.map((x) => item[x]).join('~~');
      if (map[alpha]) continue;
      newArr.push(item);
      map[alpha] = true;
    }
    return newArr;
  };

  asyncValidation = this.debouncerService.asyncValidation;
  cellSuffix = (suffix: string) => (value) => (value == null ? '-' : `${value}${suffix}`);
  addDaysToDate(date: string, days: number): string {
    // debugger;
    if (!date) return date;
    const d = new Date(date.split('T')[0]);
    d.setTime(d.getTime() + Config.TimeStampDay * days);
    // debugger;
    return d.toISOString().split('T')[0];
  }

  selectTitleByCode = (obj: { [x: string]: ICodeTitle }) => (code: string) =>
    this.selectFieldByValue(code, obj, 'title');
  // selectTitleByCode = (code: string, obj: { [x: string]: ICodeTitle }) => obj?.[code]?.title;
  selectDescriptionByCode = (obj: { [x: string]: ICodeDescription }) => (code: string) =>
    this.selectFieldByValue(code, obj, 'description');

  selectFieldByValue = <TObj>(value: string | number, obj: { [x: string]: TObj }, field: keyof TObj) => {
    // debugger;
    console.log(value, obj, field);
    return EVFunctions.strConcatenator2(value as string, obj?.[value]?.[field] as string);
  };
  /**
   * Always disable a formcontrol even if its value is updated
   * @param control
   * @returns
   */
  alwaysDisableControl = (control: FormControl) => {
    return control?.valueChanges.subscribe((r) => {
      control?.disable({ emitEvent: false });
    });
  };

  // isDateValue = (v) => (v ? v[4] == '-' && v[7] == '-' : false);
  // isDatTimeValue = (v) => (v ? v.includes('T') && v[4] == '-' && v[7] == '-' : false);

  addToSubsToCloseOnRoute = (...sub: Subscription[]) => {
    if (!Config.subsToCloseOnRoute) Config.subsToCloseOnRoute = [];
    Config.subsToCloseOnRoute.push(...sub);
  };
  clearSubsToCloseOnRoute = () => {
    Config.subsToCloseOnRoute?.forEach((x) => x?.unsubscribe());
    Config.subsToCloseOnRoute = [];
  };

  getTitleForLabel =
    <T extends { [x: string]: any }>(func: (v) => Observable<T>, descriptionField: keyof T) =>
    async (val: string) =>
      val
        ? EVFunctions.strConcatenator2(
            String(val).toUpperCase(),
            (await lastValueFrom(func(val)))?.[descriptionField],
          )
        : val;

  chunkifyArray = <T>(arr: T[], chunkUnitLength = 10): T[][] => {
    const chunks: T[][] = [[]];
    let lastChunkIndex = 0;
    for (const item of arr) {
      if (chunks[lastChunkIndex].length < chunkUnitLength) chunks[lastChunkIndex].push(item);
      else {
        chunks[++lastChunkIndex] = [item];
      }
    }
    return chunks;
  };

  isLeapYear(date?: string): boolean;
  isLeapYear(year?: number): boolean;
  isLeapYear(year: number | string = new Date().getFullYear()): boolean {
    const nyear = typeof year == 'string' ? +year.split('-')[0] : year;
    return nyear % 4 == 0 && nyear % 100 != 0 && nyear % 400 != 0;
  }
  // notify(
  //   message: string,
  //   cls?: 0 | 1 | 2 | 3,
  //   duration?: number,
  //   title?: string
  // ): MatSnackBarRef<TextOnlySnackBar> {
  //   return super.notify(message, cls, 9999999999, title);
  // }

  modalOpener<TComponent>(args: IModalConfig<TComponent>) {
    this.dialogOpener(args.component, args.config, args.valueCB, args.closeCB);
  }

  confirm = this.confirmDialogService.confirm;

  info = this.infoDialogService.info;

  strConcatenator = EVFunctions.strConcatenator;

  numberFormatter = (val: number) => (val ? this.numberPipe.transform(val) : val);
}
