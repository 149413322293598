import { HttpHeaders } from '@angular/common/http';
import { Day, ICodeTitle, IConfigImages, Status } from '../Shared/models/index.model';
import { Subscription } from 'rxjs';
import { Prototypes } from '@Shared/prototypes/prototypes';

export class Config {
  static APIAIICOServer: string =
    // 'https://ec2-18-219-154-9.us-east-2.compute.amazonaws.com:8005/core';
    'https://aiico-internal-api-core.evoluticstech.com/core';
  // 'https://aiico-core.evoluticstech.com/core';
  static APIProductionServer: string =
    // 'https://ec2-18-219-154-9.us-east-2.compute.amazonaws.com:8005/core';
    'https://development.evoluticstech.com/core';
  static APIStagingServer: string =
  // `https://prod-api-core.evoluticstech.com/core`;
    // "https://5d79-102-88-35-173.ngrok-free.app/core"
    `https://staging-api-core.evoluticstech.com/core`;
  // 'https://dev-api-jupiter.evoluticstech.com';
  // 'https://development.evoluticstech.com/core';
  // 'http://ec2-3-140-216-214.us-east-2.compute.amazonaws.com:30123/core'
  // 'http://ec2-3-140-216-214.us-east-2.compute.amazonaws.com:30123/core';
  // 'http://ec2-18-221-76-232.us-east-2.compute.amazonaws.com:8005/core';
  static APIDevelopmentServer: string = `https://staging-api-core.evoluticstech.com/core`;
  // 'http://ec2-3-140-216-214.us-east-2.compute.amazonaws.com:30123/core';
  // 'https://development.evoluticstech.com/core';
  static APIDevelopmentProcessMapServer: string = `https://processmap-service.evoluticstech.com/processmap-service/api`;

  static aiicoURLs: { [origin: string]: 1 } = {
    'http://172.16.0.121:8080': 1,
    'http://172.16.0.121:8082': 1,
    'http://127.0.0.1:8080': 1,
    'http://127.0.0.1:8082': 1,
  };
  static aiicoBaseAPI: IBaseAPI = {
    development: 'https://aiico-internal-api-core.evoluticstech.com/core',
    test: location.origin.startsWith('http://1')
      ? //  !!Config.aiicoURLs[location.origin]
        'http://172.16.0.121:8081/core'
      : 'https://aiico-internal-api-core.evoluticstech.com/core',
  };
  static coronationBaseAPI: IBaseAPI = {
    development: `https://staging-api-core.evoluticstech.com/core`,
    staging: 'http://172.16.4.44:8081/core',
  };

  static leadwayBaseAPI: IBaseAPI = {
    development: `https://staging-api-core.evoluticstech.com/core`,
    test: `https://staging-api-core.evoluticstech.com/core`,
  };
  static credit_starBaseAPI: IBaseAPI = {
    development: `https://staging-api-core.evoluticstech.com/core`,
    test: `https://staging-api-core.evoluticstech.com/core`,
  };
  static etsBaseAPI: IBaseAPI = {
    development: `https://staging-api-core.evoluticstech.com/core`,
    production: `https://prod-api-core.evoluticstech.com/core`,
    staging: `https://staging-api-core.evoluticstech.com/core`,
  };
  static secretKey: string =
    'MIICXAIBAAfBgQDfmljld9rdhvakQApmLCDOgdwdwdwo2/iwdoiP0nNERInBheMh7J/r5aU8PUAssIpGXET/8';

  static Images: IConfigImages = {
    favicon: '/assets/img/ets/favicon.ets.png',
    watermark: `/assets/img/ets/logos/logo.dark.ets.png`,
    logo: {
      dark: `/assets/img/ets/logos/logo.dark.ets.png`,
      light: `/assets/img/ets/logos/logo.light.ets.png`,
    },
    pp: { src: '/assets/img/avatar.png', min: '/assets/img/avatar.min.png' },
    other: {
      src: '/assets/img/placeholder.png',
      min: '/assets/img/placeholder.min.png',
    },
  };
  static Optioner: { num: number; letter: string }[] = [
    { num: 1, letter: 'a' },
    { num: 2, letter: 'b' },
    { num: 3, letter: 'c' },
    { num: 4, letter: 'd' },
    { num: 5, letter: 'e' },
    { num: 6, letter: 'f' },
    { num: 7, letter: 'g' },
    { num: 8, letter: 'h' },
    { num: 9, letter: 'i' },
    { num: 10, letter: 'j' },
  ];
  static Months: { id: number; short: string; isoStr: string; long: string }[] = [
    { id: 0, short: 'Jan', isoStr: '01', long: 'January' },
    { id: 1, short: 'Feb', isoStr: '02', long: 'February' },
    { id: 2, short: 'Mar', isoStr: '03', long: 'March' },
    { id: 3, short: 'Apr', isoStr: '04', long: 'April' },
    { id: 4, short: 'May', isoStr: '05', long: 'May' },
    { id: 5, short: 'Jun', isoStr: '06', long: 'June' },
    { id: 6, short: 'Jul', isoStr: '07', long: 'July' },
    { id: 7, short: 'Aug', isoStr: '08', long: 'August' },
    { id: 8, short: 'Sep', isoStr: '09', long: 'September' },
    { id: 9, short: 'Oct', isoStr: '10', long: 'October' },
    { id: 10, short: 'Nov', isoStr: '11', long: 'November' },
    { id: 11, short: 'Dec', isoStr: '12', long: 'December' },
  ];
  static ShortMonthsMap = Config.Months.map((x) => ({
    ...x,
    _short: x.short?.toLowerCase(),
  })).toMap('_short');
  static MonthsIsoStrMap = Config.Months.map((x) => ({
    ...x,
    _short: x.short?.toLowerCase(),
  })).toMap('isoStr');
  static Days = [
    { index: 0, day: Day.sunday },
    { index: 1, day: Day.monday },
    { index: 2, day: Day.tuesday },
    { index: 3, day: Day.wednesday },
    { index: 4, day: Day.thursday },
    { index: 5, day: Day.friday },
    { index: 6, day: Day.saturday },
  ];

  static Numbers: { num: number; label: string }[] = [
    { num: 1, label: 'First' },
    { num: 2, label: 'Second' },
    { num: 3, label: 'Third' },
    { num: 4, label: 'Fourth' },
    { num: 5, label: 'Fifth' },
    { num: 6, label: 'Sixth' },
    { num: 7, label: 'Seventh' },
    { num: 8, label: 'Eighth' },
    { num: 9, label: 'Ninth' },
    { num: 10, label: 'Tenth' },
  ];

  static sexes = ['Female', 'Male'];

  static readonly boolList: ICodeTitle<boolean>[] = [
    { code: true, title: 'Yes' },
    { code: false, title: 'No' },
  ];

  static Currency = '₦';
  static TimeStampDay = 86400000;
  static TimeStampWeek = 604800000;
  static FormPresets = {
    addressPlaceholder: '1, Street Address.',
  };
  static unauthorizedMessage = `You are not authorized to access this resource.`;
  static adminSetupRoute = `backoffice`;
  static alreadyOpenedRoute = 'alreadyOpened';
  static jupiterRequestHeaderKey = 'jupiter-req';
  static headerForJupiterCrossPlatformRequest = {
    options: { headers: new HttpHeaders().set(Config.jupiterRequestHeaderKey, 'true') },
  };
  static statusOptions: ICodeTitle<Status>[] = [
    { code: 'COMPLETED', title: 'Completed' },
    { code: 'PENDING', title: 'Pending' },
    { code: 'FAILED', title: 'Failed' },
  ];
  static subsToCloseOnRoute: Subscription[] = [];
}

interface IBaseAPI {
  development?: string;
  live?: string;
  production?: string;
  staging?: string;
  test?: string;
}
