import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '@Services/utility.service';
import { NotificationsComponent } from './notifications.component';

@Injectable()
export class NotificationsService {
  constructor(public dialog: MatDialog) {}
  open() {
    this.dialog.open(NotificationsComponent, {
      width: '350px',
      position: { right: '-10px', top: '80px', bottom: '50px' },
      height: '100vh',
      hasBackdrop: false,
    });
  }
}
