import { InputType } from 'ets-fe-ng-sdk';

export interface IFieldNameKVP {
  columnName: string;
  _formattedName: string;
  _inputType: InputType;
  dataType: ReportFieldType;
}
export type ReportFieldType = 'BOOLEAN' | 'TEXT' | 'DATE' | 'NUMBER';
export enum EReportOutputType {
  excel = 'EXL',
  pdf = 'PDF',
  chart = 'CH',
}
export interface IReportForm {
  calculations: IReportCalculation[];
  code?: string;
  data?: { [field: string]: string | number | boolean }[];
  dateParameterBasis?: string;
  dateParameterValue?: string;
  defaultOutput?: EReportOutputType;
  description: string;
  filePath?: string;
  sqlQuery?: string;
  encodedData?: string;
  filters?: Partial<IReportFilter>[];
  generateReport?: boolean;
  useRawQuery?: boolean;
  generateStatus?: GenerateStatus;
  id?: number;
  joiningTables?: IReportJoiningTable[];
  largeData?: boolean;
  outputs?: IReportOutput[];
  primaryTable?: string;
  reportFormat?: EReportFormat;
  reportGroup?: string;
  reportHeader?: string;
  sorts?: Partial<IReportSort>[];
  summary?: number;
  tableGroup?: string;
  tableSchema?: string;
  userGroup?: Partial<IUserGroup>[];
  webuserGroup?: Partial<IUserGroup>[];
  writerId?: number;
}
interface IUserGroup {
  allowedGroups: string;
  createdBy: string;
  createdOn: string;
  description: string;
  group: string;
  id: number;
  reportCode: string;
  reportId: number;
  updatedBy: string;
  updatedOn: string;
  webUserGroup: string;
}
export enum EReportFormat {
  // excel = 'xlsx',
  csv = 'csv',
  pdf = 'pdf',
  txt = 'txt',
  json = 'json',
}
export interface IReportFilter {
  tableSchema: any;
  id?: any;
  dataType: ReportFieldType;
  connector: 'AND' | 'OR';
  name: string;
  operator: string;
  value: string | number | boolean;
  fieldName: string;
  fieldType: string;
  tableName: string;
}
export interface IReportJoiningTable {
  fieldName: string;
  id: number;
  primaryTableLink: string;
  secondaryTableLink: string;
  format: string;
  tableGroup: string;
  tableLink: string;
  tableName: string;
  label: string;
  tableSchema: string;
}
export interface IReportOutput {
  id?: number;
  format: string;
  fieldName: string;
  truncate: string;
  label: string;
  fieldType: string;
  tableName?: string;
}

export interface IReportSort {
  id: any;
  fieldName: string;
  sortBy: string;
  fieldType: string;
  tableName: string;
}

export interface IReportGroup {
  primaryTable: string;
  code: string;
  description: string;
  reportHeader: string;
  reportGroup: string;
  tableGroup: string;
  createdBy: string;
  filePath: string;
  generateReport: boolean;
  writerId: string;
  reportFormat: string;
  filters: IReportFilter[];
  outputs: IReportOutput[];
  userGroup: any[];
  sorts: IReportSort[];
  joiningTables: IReportJoiningTable[];
}
export interface IReportWebUser {
  id: number;
  webGroup: string;
  description?: any;
  defaultMenu: string;
  directSupervisor?: any;
  primaryCompany?: any;
  primaryBranch?: any;
  createdBy?: any;
  updatedBy?: any;
}
export interface IReportHistory {
  id: number;
  createdBy: string;
  createdOn: string;
  processedOn: string;
  docKey: string;
  fileName: string;
  generateStatus: GenerateStatus;
  reportCode: string;
  encodedData: string;

  reportHeader?: string;
  description?: string;
}
type GenerateStatus = 'EMPTY' | 'COMPLETED' | 'PENDING';

export const SQLBlacklist: { field: string }[] = [
  { field: 'INSERT' },
  { field: 'UPDATE' },
  { field: 'DELETE' },
  { field: 'COUNT' },
];

export interface IReportCalculation {
  header: string;
  id?: number;
  expression: IReportCalculationExpression[];
}
export interface IReportCalculationExpression {
  fieldName: string;
  operator: EReportCalcOperator;
  value: number;
  order: number;
  type: EReportCalcTokenType;
}

export enum EReportCalcTokenType {
  value = 'value',
  operator = 'operator',
  variable = 'variable',
}

export enum EReportCalcOperator {
  multiply = 'x',
  add = '+',
  subtract = '—',
  divide = '/',
}
