<div class="{{appS.system}} form-nav-case">
  <header class=" ">
    <div class="circle-1 circle-bg start-circle">

    </div>
    <div class="circle-2 circle-bg end-circle">

    </div>

    <div class="align-items-center justify-content-between m-0 row">
      <div class="col-md-1 col-2 action-button">
        <a (click)="goBack()" class="arrow pointer">
          <fa-icon [icon]="faArrowAltCircleLeft" size="3x"></fa-icon>
        </a>
      </div>
      <div class="col-md-9 col-7 center page-title"  >
        {{(cashtitle||title||appS.pageName)|appTranslate|async}}
      </div>
      <div class="col-md-1 col-2 action-button text-end">
        @if (!isModal) {
<a class="arrow pointer"
          routerLink="/{{this.appS?.getCurrentSystemMetadata?.rootPath||this.appS?.getFirstAvailableSystem?.rootPath}}">
          <fa-icon [icon]="faHome" size="3x"></fa-icon>
        </a>
}
      </div>
    </div>
  </header> 
</div>