import { Config } from '@configs/index.config';
import { ERole, IUser } from '../../Authentication/auth-extras/authentication.interface';
import { MenuItem } from './IMenuItem';
import { CreateUser, UserGroup } from './life/user/Users';
import { IConfigImages, ESystem, ELanguage, EMenuType, ESystemBusLine } from './index.model';
import { Environment as SDKE, environment as SDKenvironment } from 'ets-fe-ng-sdk';
import { Config as JupiterConfig } from 'projects/evolutics-admin-ui/src/app/configs/index.config';
import { IWebUser } from '@Authentication/web-user/web-user-authentication.model';
import { ICompanyConfig } from 'projects/evolutics-client-ui/src/app/Life/admin/configuration/config';
import { EClient } from '../enums/client.enum';
import { IJupiterCompany } from 'projects/evolutics-admin-ui/src/app/shared/models/admin-company.interface';
import { IAdminUser } from 'projects/evolutics-admin-ui/src/app/shared/models/admin-user.interface';
import { EAccessType } from '../enums/accessType.enum';

export class Environment extends SDKE {
  images: IConfigImages = Config.Images;
  logReq = true;
  useAuthCookies: boolean;
  // private _userMenu: IUserMenu;
  companyConfig: {} = {};
  versionNo: string;
  readonly userGroupStorageKey = 'userGroup';
  userGroup: UserGroup;
  userCompanyConfig: ICompanyConfig;
  activeSystems: ESystem[];
  currentSystem: ESystem;
  clientMenus: MenuItem[];
  readonly jupiterCompanyCode: string = 'COM1';
  readonly jupiterAPIKey: string = 'ZNGOCsOogYFOzzb';
  readonly jupiterSystem = 'CORE';
  pageNoTextFormat: boolean;
  readonly helpLink = '/help' as any;
  currentBusLine: ESystemBusLine;
  // public get userMenu(): IUserMenu {
  //   return this._userMenu;
  // }
  // public set userMenu(value: IUserMenu) {
  //   this._userMenu = value;
  //   this._userMenu.userMenuConfigDetails = this._userMenu?.userMenuConfigDetails?.filter(
  //     (x) => x.editAccess || x.viewAccess || x['access'],
  //   );
  // }
  user?: IUser;
  adminUser?: IAdminUser;
  userProfile?: CreateUser;
  email: string;
  sourceLanguage: ELanguage;
  jupiterAPIURL: string = JupiterConfig.APIProductionServer;
  phoneNumber: string;
  salesPortalURL: string;
  reinsPortalURL: string;
  defaultCurrency = '₦';
  isSalesPortal = false;
  override useUserMenu = true;
  webUser: IWebUser = null;
  company: IJupiterCompany;
  client: EClient;
  useBatchUploadTransactions: boolean = true;
  processMapBaseURL = 'https://processmap-service.evoluticstech.com/processmap-service/api';
  readonly userCompanyKey = 'userCompany';
  isJupiter: boolean;
  accessType: EAccessType;
  constructor(
    public production: boolean,
    name: string,
  ) {
    super(production, name);
    SDKenvironment.reinit(this);
    // Object.assign(this,ETSenvironment)
  }
  get primaryCompanyCode() {
    return this.userProfile?.users?.primaryCompany;
  }
  get menuLayout() {
    return this.userProfile?.users?.menuLayout || EMenuType.horizontal;
  }
  get userName() {
    return this.userProfile?.users?.userName;
  }
  get userIsSuperAdmin() {
    return this.user?.userRoleType == ERole.superAdmin;
  }
  get userKey() {
    return this.userProfile?.users?.userName;
  }
  get userCompanyCode() {
    return this.userProfile?.users?.primaryCompany;
  }
  get token() {
    return this.user?.token || this.webUser?.token;
  }

  get isPrivate() {
    return !!Config.aiicoURLs[location.origin];
  }
  get imageUrl() {
    return this.userProfile?.users?.imageUrl;
  }
  public get translate(): boolean {
    return (this.userProfile?.users?.language && this.userProfile?.users?.language != ELanguage.EN) || false;
  }
  // set translate(value: boolean) {
  //   super.translate = value;
  // }
  public get targetLanguage(): ELanguage {
    return (this.userProfile?.users?.language as any) || super.targetLanguage;
  }
  // set targetLanguage(value: ELanguage) {
  //   super.targetLanguage = value;
  // }
}
