import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { ELanguage, ICodeDescription } from 'ets-fe-ng-sdk';
import { ERole, IUser } from '@Authentication/auth-extras/authentication.interface';
import { CreateUser } from '@Shared/models/life/user/Users';
import { environment } from '@environments/environment';
export namespace AuthStore {
  //#region STORE

  export interface IState {
    auth?: IUser;
    userProfile?: CreateUser;
    // userMenu?: IUSEr;
  }

  const initialState: IState = {};
  //#endregion

  //#region ACTIONS
  export const actions = createActionGroup({
    source: 'AUTH API',
    events: {
      getAuthUserL: emptyProps(),
      logout: props<{ redirectRoute?: string }>(),
      logoutSuccess: emptyProps(),
      // getAuthUserLSuccess: props<{ auth: IUser; userProfile: CreateUser }>(),
      getAuthUserLError: emptyProps(),
      getUserO: props<{ userID?: number }>(),
      getUserOSucces: props<{ userProfile?: CreateUser }>(),
      saveUserToLocalSuccess: emptyProps(),
      setAuthUser: props<{ auth: IUser; userProfile?: CreateUser }>(),
      setAuth: props<{ auth: IUser }>(),
      setUser: props<{ userProfile?: CreateUser }>(),
      // setUserComplete: props<{ userProfile?: CreateUser }>(),
      // checkUser: props<{ userProfile?: CreateUser }>(),
      checkUserSuccess: emptyProps(),
      fetchUserStateDetails: props<{ userProfile?: CreateUser }>(),
      fetchUserStateDetailsLocal: props<{ userProfile?: CreateUser }>(),
      // checkUserError: props<{error:string}>(),

      // getUserMenuL: props<{ userProfile?: CreateUser }>(),
      // getUserMenuOSuccess: props<{ userProfile?: CreateUser }>(),
      // getUserLanguageL: props<{ userProfile?: CreateUser }>(),
      // getUserLanguageOSuccess: props<{ userProfile?: CreateUser }>(),
      // getUsergGroupL: props<{ userProfile?: CreateUser }>(),
      // getUsergGroupOSuccess: props<{ userProfile?: CreateUser }>(),
    },
  });
  //#endregion

  //#region SELECTORE

  export namespace selectors {
    const select = (state: IAppState) => state.auth;
    export const isSuperAdmin = createSelector(
      select,
      (state) => state.auth?.userRoleType == ERole.superAdmin,
    );
    export const auth = createSelector(select, (state) => state.auth);
    export const userProfile = createSelector(select, (state) => state.userProfile);
    
    export const needsTranslation = createSelector(select, (state) =>
      state.userProfile?.users?.language && state.userProfile.users.language != ELanguage.EN
        ? state.userProfile.users.language
        : null,
    );
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.setAuth, (state, { auth }) => {
      environment.user = auth;
      // debugger;
      return { ...state, auth };
    }),
    on(actions.setUser, (state, { userProfile }) => {
      environment.userProfile = userProfile;
      return { ...state, userProfile };
    }),
    on(actions.getUserOSucces, (state, { userProfile }) => {
      environment.userProfile = userProfile;
      return { ...state, userProfile };
    }),
    on(actions.setAuthUser, (state, { auth, userProfile }) => {
      environment.user = auth;
      environment.userProfile = userProfile;
      // debugger;
      return { ...state, auth, userProfile };
    }),
  );
  //#endregion
}
