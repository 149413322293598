<div mat-dialog-title class="d-flex justify-content-between p-0 align-items-center">
  <h5 class="m-0 p-0" [innerHTML]="header|appTranslate|async"></h5>
  <!-- <app-btn group="close" (mclick)="close()"></app-btn> -->
  <div class="row row-cols-auto gx-1 align-items-center">
    <ng-content select="[headerBtns]"></ng-content>
    <!-- <app-btn customIcon="fa fa-close" [iconBtn]="true" (mclick)="close()" type="clear" mclass="text-danger" /> -->

    <button  type="button" (click)="close()" class="btn-close text-danger" aria-label="Close"></button>
    <!-- <button mat-dialog-close type="button" (click)="close()" class="btn-close text-danger" aria-label="Close"></button> -->
    <!-- <button mat-icon-button (click)="close()" class="btn-outline-danger"><i class="fa fa-close"></i></button> -->
  </div>
</div>