import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { UtilityService } from '@Services/utility.service';
import { WatermarkComponent } from '../../Reusables/reusable-comps/watermark/watermark.component';
import { FormNavigationComponent } from '../navigation/form-navigation/form-navigation.component';
import { ModalHeaderComponent } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-modal-form-layout',
    templateUrl: './modal-form-layout.component.html',
    styleUrls: ['../form-layout/form-layout.component.scss'],
    standalone: true,
    imports: [FormNavigationComponent, WatermarkComponent,ModalHeaderComponent]
})
export class ModalFormLayoutComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() title: string;
  constructor(public uS: UtilityService) {}

  ngOnInit(): void {}
  onClose() {
    this.close.emit();
  }
}
