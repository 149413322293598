/**
 * Contains a map of field names to labels
 */
export const fieldToLabelMap: { [field: string]: string } = {
  accField: 'Account Field',
  accCode: 'Account Code',
  agentNo: 'Agent Number',
  annuityOnClaimEscalRate: 'Annuity On Claim Escalation Rate',
  annuityOnClaimFreq: 'Annuity On Claim Frequency',
  annuityOnClaimTermFreq: 'Annuity On Claim Term Frequency',
  annuityOnClaimTermMonths: 'Annuity On Claim Term Months',
  annuityRevPercent: 'Annuity Revisonary Percent',
  applOn: 'Application On',
  assetNo: 'Asset Number',
  auth: 'Authorisation',
  authBy: 'Authorisation By',
  authComplete: 'Authorisation Complete',
  authOn: 'Authorisation On',
  authReqPayout: 'Authorisation Required Payout',
  autoAttachWf: 'Automatically Attach Workflow',
  autoPayoutThreshold: 'Auto Payout Threshold',
  busLine: 'Business Line',
  claimNo: 'Claim Number',
  clientNo: 'Client Number',
  cnNo: 'Credit Note Number',
  conversionProb: 'Conversion Probability',
  conversionQuoteNo: 'Conversion Quote Number',
  convertToProd: 'Convert To Product',
  dashBoard: 'Dashboard',
  coverTermMo: 'Cover Term Months',
  depAdmin: 'Department Administrator',
  depositIntRate: 'Deposit Interest Rate',
  detailReq: 'Details Required',
  dnNo: 'Debit Note Number',
  docCategory: 'Document Category',
  docLink: 'Document Link',
  drCr: 'Debit / Credit',
  docSensitivity: 'Document Sensitivity',
  docStatus: 'Document Status',
  doNotAutoPrint: 'Do Not Automatically Print',
  dvAuthBy: 'Discharge Voucher Auth By',
  dvAuthThreshold: 'Discharge Voucher Authorisation Threshold',
  dvCreatedBy: 'Discharge Voucher Created By',
  dvCreatedOn: 'Discharge Voucher Created On',
  dvExecutedOn: 'Discharge Voucher Executed On',
  dvReqAuth: 'Discharge Voucher Requires Authorisation',
  externalRef: 'External Reference',
  fcl: 'Free Cover Limit',
  fieldConv: 'Field Conversion',
  frequencyType: 'Frequency Type',
  groupNo: 'Group Number',
  ifrsGroupCode: 'IFRS Group Code',
  ifYesListValue: 'If Yes List Conditions',
  ifYesQuestCode: 'If Yes Question Code',
  ifYesText: 'If Yes Text',
  insType: 'Insurance Type',
  loanIntRate: 'Loan Interest Rate',
  loanNo: 'Loan Number',
  lowerLimitAge: 'Lower Qualify Age',
  lowerLimitSa: 'Lower Qualify Sum Assured',
  mandatory: 'Mandatory',
  masterEmailDoc: 'Master Email Document',
  nextAuthTier: 'Next Authorisation Tier',
  noOfAssets: 'Number Of Assets',
  noOfCovers: 'Number Of Covers',
  noOfLives: 'Number Of Lives',
  noOfMembers: 'Number Of Members',
  noOfPay: 'Number Of Payments',
  otherDocLinked: 'Other Document Linked',
  ownerClientNo: 'Owner Client Number',
  ownerCrmClientNo: 'Owner CRM Client Number',
  pathDocEmailed: 'Path Document Emailed',
  pathDocFailedDelivery: 'Path Document Failed Delivery',
  pathDocNotAutoPrinted: 'Path Document Not Automatically Printed',
  pathDocNotEmailed: 'Path Document Not Emailed',
  payinFreq: 'Payin Frequency',
  polDocIssue: 'Policy Document Issue',
  policyNo: 'Policy Number',
  policyNoSuffix: 'Policy Number Suffix',
  policyTermMo: 'Policy Term Month',
  premEscalationRate: 'Premium Escalation Rate',
  premFreq: 'Premium Frequency',
  premIndexTable: 'Premium Index Table',
  premiumToBen: 'Premium To Benefit',
  premPayTermMo: 'Premium Pay Term Month',
  primaryRelMgr: 'Primary Relationship Manager',
  priorPrem: 'Prior Premium',
  prodClass: 'Product Class',
  productCode: 'Product Code',
  productPolFee: 'Product Policy Fee',
  proformaNo: 'Proforma Number',
  providerNo: 'Provider Number',
  quoteAuth: 'Quote Authorisation',
  quoteAuthBy: 'Quote Authorisation By',
  quoteAuthOn: 'Quote Authorisation On',
  quoteAuthReq: 'Quote Authorisation Request',
  quotedOn: 'Quoted On',
  quoteNo: 'Quote Number',
  quoteNoConvertedTo: 'Quote Number Converted To',
  referrerNo: 'Referrer Number',
  refNo: 'Reference Number',
  refType: 'Reference Type',
  responseReq: 'Response Required',
  riskCat: 'Risk Cat',
  rm: 'Risk Management',
  saIndexTable: 'Sum Assured Index Table',
  sar: 'Sum At Risk',
  seqNo: 'Sequence Number',
  srm: 'Strategic Risk Management',
  templateCat: 'Template Category',
  TOTAL_AMOUNT: 'Total Amount',
  totalAdj: 'Total Adjustment',
  totalPolFee: 'Total Policy Fee',
  totalSar: 'Total Sum At Risk',
  transNo: 'Transaction Number',
  transReason: 'Transaction Reason',
  upperLimitAge: 'Upper Qualify Age',
  upperLimitSa: 'Upper Qualify Sum Assured',
  uwPending: 'Underwriting Pending',
  valueFrom: 'Value From',
  valueTo: 'Value To',
  valueUnit: 'Value Unit',
  authLimitCommOverride: 'Authorisation Limit % on Commission',
  authLimitPremAdjust: 'Authorisation Limit % on Premium Adjustments',
};
//Adds lowercase format of key names
Object.keys(fieldToLabelMap).forEach((x) => {
  fieldToLabelMap[x.toLowerCase()] = fieldToLabelMap[x];
});
